// import fs from 'fs';
import { FormatTime , FormatDate} from './date.util';

export const downloadXLSFile = async (data) => {
    try {
        const outputFilename = `Redenciones - ${FormatDate(Date.now())} ${FormatTime(Date.now())}.xlsx`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();

    } catch (error) {
        throw Error(error);
    }
}