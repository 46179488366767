import React from "react";

export default class Cards extends React.Component {
  render() {
    return (
      <div className="bg-card rounded-sm p-3 card-custom">
        <p className={`m-0 ${this.props.centered ? "text-center" : ""}`}>
          {this.props.title}
        </p>
        <h4 className="lead ">{this.props.content}</h4>
      </div>
    );
  }
}
