import React from "react";
import { Button, Col, Row } from "react-bootstrap";
// import { FormatDate } from "../../utils/date.util";
// import TypeProducts from "../../constants/typesproducts";

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReadonly: true,
      disabledButton: true,
    };
  }

  render() {
    return (
      <>
        <div>
          <Row>
            <Col>
              <p style={{ paddingTop: "15px" }}>{this.props.data.infoText}</p>
              <div class="form-check dialog-info">
                <input
                  onChange={(e) =>
                    this.setState({ disabledButton: !e.target.checked })
                  }
                  class="form-check-input"
                  type="checkbox"
                  id="privacy"
                />
                <label class="form-check-label ml-2" for="privacy">
                  Acepto los términos y condiciones
                </label>
              </div>
            </Col>
          </Row>
        </div>
        <div className="text-right mt-3">
          <Button
            disabled={this.state.disabledButton}
            className="mx-1 btn-lightblue"
            onClick={this.props.commit}
          >
            Aceptar
          </Button>
        </div>
      </>
    );
  }
}

export default Info;
