import moment from "moment";

export function FormatDate(date, format = "DD/MM/YYYY") {
    if (!date) return "Fecha invalida";
    return moment(date).utc().format(format);
}

export function FormatTime(date) {
    return moment(date).subtract('hour', 4).utc().format("hh:mm A");
}

export function DiffDate(dateStart, dateEnd) {
    return moment(dateEnd).diff(dateStart, 'day' );
}

