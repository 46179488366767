const Tables = {
  DASHBOARD: 1,
  OFFERTS: 2,
  OFFICES: 3,
  RESPONSABLE: 4,
  REDEMPTIONS: 5,
  CATALOG: 6,
  CATEGORY: 7,
  REDEMPTIONSDASHBOARD: 8,
};

export default Tables;
