import React, { lazy, Suspense } from "react";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { store } from "../app/store";

import dasboard from "../views/dasboard";
import profile from "../views/profile";
import login from "../views/login";
import orderdetail from "../views/orderdetail";
import qrcode from "../views/qrcode";

//Retention
const AccessChooser = lazy(() => import("../views/Retention/AccessChooser"));
const ExchangeOffers = lazy(() => import("../views/Retention/ExchangeOffers"));

const requireLogin = (to, from, next) => {
  const state = store.getState();

  if (state.isLogin) {
    next();
  } else {
    next.redirect("/login");
  }
};

class Routes extends React.Component {
  render() {
    return (
      <Suspense fallback={<>Cargando...</>}>
        <Router exact path="/">
          <GuardProvider guards={[requireLogin]}>
            <Switch>
              <GuardedRoute
                exact
                path="/retentions/access-chooser"
                component={AccessChooser}
              />
              <GuardedRoute exact path="/dashboard" component={dasboard} />
              <GuardedRoute exact path="/profile" component={profile} />
              <GuardedRoute
                exact
                path="/orderdetail/:id"
                component={orderdetail}
              />
              <GuardedRoute
                exact
                path="/retentions/exchange-offers"
                component={ExchangeOffers}
              />
              <Route exact path="/login" component={login} />
              <Route path="/qrcode" component={qrcode} />
              <Route path="*">
                <Redirect to="/dashboard" />
              </Route>
            </Switch>
          </GuardProvider>
        </Router>
      </Suspense>
    );
  }
}
export default Routes;
