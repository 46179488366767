import { axiosServices } from "./config.services";

export async function RegisterOffert(model) {
  return await axiosServices.post("/ofertas/create", model);
}

export async function DashboardOfferts(model) {
  return await axiosServices.post("/ofertas/listaofertasgrafico", model);
}

export async function UpdateOffert(model) {
  return await axiosServices.post("/ofertas/update", model);
}

export async function GetAllOffert() {
  return await axiosServices.get("/ofertas/listpop");
}

export async function GetAllOffertById(Id) {
  return await axiosServices.get(`/ofertas/listaofertasporproveedor/${Id}`);
}

export async function GetOffertById(id) {
  return await axiosServices.get(`/ofertas/find/${id}`);
}

export async function GetOffertByType(type) {
  return await axiosServices.get(`/ofertas/listaofertasportipo/${type}`);
}
