import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Button, Form, Badge } from "react-bootstrap";
import { connect } from "react-redux";

import Navbar from "../components/navbar/navbar";
import CustomTable from "../components/customtable/customtable";
import table from "../constants/tables.enum";
import ModalDialog from "../components/dialog/modaldialog";
import Forms from "../constants/forms.enum";
import { GetByOffertId } from "../services/redentions.services";
import { GetOffertById, UpdateOffert } from "../services/offerts.services";
import { GetEmployeeByCompanyId } from "../services/employee.services";
import { GetAllOfficeById } from "../services/office.services";
import { GetAllTypes } from "../services/type.services";
import { DiffDate, FormatDate } from "../utils/date.util";
import { CurrencyFormat } from "../utils/currency.util";
import { Multiselect } from "multiselect-react-dropdown";
import * as $ from "jquery";
import axios from "axios";
import swal from "sweetalert2";
import { BlobServiceClient } from "@azure/storage-blob";
import { v4 as uuidv4 } from "uuid";

function mapStatetoProp(state) {
  return state;
}

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedRedention: {},
      title: "Canjes",
      titleOffice: "Sucursales",
      isOpen: false,
      isCanjeOpen: false,
      offertDetail: {},
      columns: ["Socio", "Fecha", "Hora", "Canjeada"],
      table: table.REDEMPTIONS,
      readonly: true,
      company: {},
      offices: {},
      providerId: this.props.provider,
      sucursales: [],
      types: [],
      isInvalid: false,
      disableDdl: false,
    };

    this.style = {
      chips: {
        background: "#3BC1D3",
      },
      searchBox: {
        background: "#1C1E24",
      },
      multiselectContainer: {
        color: "#3BC1D3",
      },
      optionContainer: {
        border: "2px solid",
      },
      option: {
        color: "#3BC1D3",
        background: "#1C1E24",
      },
    };
  }

  componentDidMount() {
    GetByOffertId(this.props.match.params.id).then((resp) => {
      this.setState({ data: resp.data.result });
    });

    GetAllOfficeById(this.props.empresa._id).then((resp) => {
      this.setState({ offices: resp.data.result });
    });

    GetOffertById(this.props.match.params.id).then((resp) => {
      this.setState({
        offertDetail: resp.data.result,
        sucursales: resp.data.result.sucursales,
      });
    });

    GetEmployeeByCompanyId(this.props.empresa._id).then((resp) => {
      this.setState({ company: resp.data.result });
    });

    GetAllTypes().then((resp) => this.setState({ types: resp.data.result }));
  }

  async SaveChange(e, frmData) {
    let form = document.getElementById("frm");

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();

      this.setState({
        isInvalid: true,
      });
      return;
    }

    let diffDay = DiffDate(frmData.fecha_inicial, frmData.fecha_final);

    if (diffDay < 0) {
      swal.fire(
        "Advertencia",
        "La fecha inicial no puede ser superior a la final.",
        "warning"
      );
      return;
    }

    if (diffDay < frmData.cantidad_dias_minimos) {
      swal.fire(
        `Advertencia`,
        `La cantidad minima de dias es ${frmData.cantidad_dias_minimos} y se seleccionaron ${diffDay}`,
        "warning"
      );
      return;
    }

    if (frmData.cantidad < frmData.cantidad_ofertas_minimo) {
      swal.fire(
        "Advertencia",
        `La cantidad minima de ofertas es ${frmData.cantidad_ofertas_minimo} y se agregaron ${frmData.cantidad}`,
        "warning"
      );
      return;
    }

    let params = {
      _id: this.props.match.params.id,
      nombre: frmData.nombre,
      descripcion: frmData.descripcion,
      palabras_claves: frmData.palabras_claves,
      foto: frmData.foto,
      precio: frmData.precio,
      multiple: frmData.multiple,
      cantidad: frmData.cantidad,
      proveedor: this.state.providerId,
      sucursales: this.state.sucursales,
      cantidad_ofertas_minimo: frmData.cantidad_ofertas_minimo,
      cantidad_dias_minimos: frmData.cantidad_dias_minimos,
      responsable: frmData.responsable,
      tipo: frmData.tipo,
      fecha_inicial: frmData.fecha_inicial,
      fecha_final: frmData.fecha_final,
      estado: frmData.estado,
    };

    UpdateOffert(params).then((resp) => {
      swal.fire(
        "información",
        resp.data.message,
        resp.data.success ? "success" : "error"
      );

      GetOffertById(this.props.match.params.id).then((resp) => {
        this.setState({ offertDetail: resp.data.result });
      });
    });

    this.setState({ readonly: !this.state.readonly, offertDetail: frmData });
  }

  DeleteOffert = () => {
    swal
      .fire({
        title: "Esta seguro que desea eliminar esta oferta?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: `Eliminar`,
        confirmButtonColor: "#dc3545",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let params = { ...this.state.offertDetail };
          params.estado = "borrado";
          await UpdateOffert(params).then((resp) => {
            swal.fire(
              "Información",
              "Oferta eliminada exitosamente",
              "success"
            );
            this.props.history.goBack();
          });
        }
      });
  };

  ShowFileDialog() {
    $("#file-input").trigger("click");
  }

  FileUpload = (event, frm) => {
    let url = `${process.env.REACT_APP_CLOUDINARYURL}${process.env.REACT_APP_CLOUDNAME}/upload`;
    const formData = new FormData();

    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_UPLOADPRESET);

      axios
        .post(url, formData)
        .then((resp) => {
          frm.foto = resp.data.secure_url;
        })
        .catch((errm) => {
          swal.fire("Error", "Error al intentar cargar la imagen", "error");
        });
    }
  };

  UploadImage = (event, frm) => {
    console.log("UploadImage:", event);

    let file = event.target.files[0];

    console.log("UploadImage:", file);

    if (!file) {
      console.log("No hay un archivo seleccionado.");
      return "";
    }

    const fileName = (uuidv4() + "_" + file.name)
      .toLowerCase()
      .replaceAll(" ", "_")
      .replaceAll("-", "_");

    console.log("UploadImage:", fileName);

    const blobServiceClient = BlobServiceClient.fromConnectionString(
      process.env.REACT_APP_AZURE_STORAGE_CONNECTION_STRING
    );

    const containerClient = blobServiceClient.getContainerClient(
      process.env.REACT_APP_AZURE_CONTAINER_NAME
    );

    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    blockBlobClient.uploadData(file).then(
      () => {
        const url = `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net/${process.env.REACT_APP_AZURE_CONTAINER_NAME}/${fileName}`;

        console.log("UploadImage:", { url });

        console.log("UploadImage:", event.target.id);

        frm.foto = url;
      },
      (error) => {
        console.error("Error en la carga de archivos a Azure:", error);
        swal.fire("Error", "Error al intentar cargar la imagen", "error");
      }
    );
  };

  showDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  showCanjeDialog = (item) => {
    this.setState({
      isCanjeOpen: !this.state.isCanjeOpen,
      selectedRedention: item,
    });
  };

  onSelect = (selectedList, selectedItem) => {
    let lsPlace = this.state.sucursales;
    lsPlace.push(selectedItem._id);
    this.setState({
      sucursales: lsPlace,
    });
  };

  onRemove = (selectedList, removedItem) => {
    let lsPlace = this.state.sucursales;
    let indx = lsPlace.findIndex((x) => x === removedItem._id);
    lsPlace.splice(indx, 1);
    this.setState({
      sucursales: lsPlace,
    });
  };

  SelectAllOffice(e) {
    if (e.target.checked) {
      let lsPlace = [];
      this.state.offices.forEach((x) => lsPlace.push(x._id));
      this.setState({ sucursales: lsPlace, disableDdl: true });
    } else {
      this.setState({ sucursales: [], disableDdl: false });
    }
  }

  DetailRender() {
    let content = "";
    let frm = { ...this.state.offertDetail };

    if (this.state.readonly) {
      content = (
        <div>
          <Row className="mb-4">
            <Col md={9}>
              <h2 className="mb-0 mt-2">
                <span
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                  className="pointer text-lightblue mx-2"
                >
                  <i className="fas fa-chevron-left"></i>
                </span>
                Detalle de la oferta
              </h2>
            </Col>
            <Col md={3} className="text-right pt-3">
              <Button
                onClick={this.DeleteOffert}
                variant="link"
                className="text-danger"
              >
                Eliminar
              </Button>
              <Button
                onClick={() => {
                  this.setState({ readonly: !this.state.readonly });
                }}
                className="btn-lightblue"
              >
                Editar
              </Button>
            </Col>
          </Row>

          <Row>
            <Col md={8}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <label className="text-muted mb-0">Nombre</label>
                    <p className="text-break">
                      {this.state.offertDetail.nombre}
                    </p>
                  </Form.Group>

                  <Form.Group className="mt-5 mb-4">
                    {this.state.offertDetail.palabras_claves
                      ?.split(",")
                      .map((val, indx) => (
                        <Badge
                          key={indx}
                          variant="secondary"
                          className="p-2 mx-1 rounded-pill"
                        >
                          {val}
                        </Badge>
                      ))}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-0">
                    <label className="text-muted mb-0">Descripción</label>
                    <p>{this.state.offertDetail.descripcion}</p>
                  </Form.Group>

                  <Form.Group>
                    <label className="text-muted mb-0">Compra múltiple</label>
                    <p>{this.state.offertDetail.multiple ? "Si" : "No"}</p>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <label className="text-muted mb-0">Precio</label>
                        <p>
                          {CurrencyFormat(
                            this.state.offertDetail.precio?.toString()
                          )}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <label className="text-muted mb-0">Cantidad</label>
                        <p>{this.state.offertDetail.cantidad}</p>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <label className="text-muted mb-0">Sucursales:</label>{" "}
                        <br />
                        <Button
                          variant="link"
                          onClick={() => this.showDialog()}
                          className="pl-0 text-lightblue"
                        >
                          Ver sucursales
                        </Button>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <label className="text-muted mb-0">
                          Responsable de la oferta
                        </label>
                        <p>{this.state.offertDetail._responsable_?.nombre}</p>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <div>
                <label className="text-muted mb-0">Imagen</label> <br />
                <img
                  src={this.state.offertDetail.foto}
                  width="350"
                  height="200"
                  alt="product"
                />
              </div>
              <Form.Group>
                <label className="text-muted mb-0">
                  Fecha de inicio y finalización
                </label>
                <p>
                  {FormatDate(this.state.offertDetail.fecha_inicial)} -{" "}
                  {FormatDate(this.state.offertDetail.fecha_final)}
                </p>
              </Form.Group>
              <Form.Group>
                <label className="text-muted mb-0">Tipo de oferta</label>
                <p>{this.state.offertDetail._tipo_?.nombre}</p>
              </Form.Group>
            </Col>
          </Row>
        </div>
      );
    } else {
      content = (
        <Form id="frm" noValidate validated={this.state.isInvalid}>
          <Row className="mb-4">
            <Col md={9}>
              <h2 className="mb-0 mt-2">Detalle de la oferta</h2>
            </Col>
            <Col md={3} className="text-right pt-3">
              <Button
                onClick={() => {
                  this.setState({ readonly: !this.state.readonly });
                }}
                className="btn-lightblue mx-2"
              >
                Cancelar
              </Button>
              <Button
                onClick={(e) => {
                  this.SaveChange(e, frm);
                }}
                className="btn-lightblue"
              >
                Guardar
              </Button>
            </Col>
          </Row>

          <Row>
            <Col md={8}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <label className="text-muted mb-0">Nombre</label>
                    <textarea
                      required
                      maxLength="140"
                      defaultValue={this.state.offertDetail.nombre}
                      onChange={(e) => (frm.nombre = e.target.value)}
                      className="input-black form-control mb-2"
                      rows="4"
                    />
                    <Form.Control.Feedback type="invalid">
                      Campo requerido
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <label className="text-muted mb-0">Palabras claves</label>
                    <Form.Control
                      defaultValue={this.state.offertDetail.palabras_claves}
                      onChange={(e) => (frm.palabras_claves = e.target.value)}
                      className="input-black"
                    />
                    <Form.Control.Feedback type="invalid">
                      Campo requerido
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <label className="text-muted mb-0">Descripción</label>
                    <Form.Control
                      defaultValue={this.state.offertDetail.descripcion}
                      onChange={(e) => (frm.descripcion = e.target.value)}
                      className="input-black"
                    />
                    <Form.Control.Feedback type="invalid">
                      Campo requerido
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <label className="text-muted mb-0">Compra múltiple</label>{" "}
                    <br />
                    <Form.Check
                      defaultChecked={this.state.offertDetail.multiple}
                      onChange={(e) => (frm.multiple = true)}
                      className="mx-3"
                      inline
                      label="Si"
                      type={"radio"}
                      name={`inline-radio-1`}
                    />
                    <Form.Check
                      defaultChecked={!this.state.offertDetail.multiple}
                      onChange={(e) => (frm.multiple = false)}
                      className="mx-3"
                      inline
                      label="No"
                      type={"radio"}
                      name={`inline-radio-1`}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <label>Cantidad mínima de días</label>
                        <Form.Control
                          defaultValue={
                            this.state.offertDetail.cantidad_dias_minimos
                          }
                          onChange={(e) =>
                            (frm.cantidad_dias_minimos = e.target.value)
                          }
                          className="input-black"
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo requerido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <label>Cantidad mínima de ofertas</label>
                        <Form.Control
                          defaultValue={
                            this.state.offertDetail.cantidad_ofertas_minimo
                          }
                          onChange={(e) =>
                            (frm.cantidad_ofertas_minimo = e.target.value)
                          }
                          className="input-black"
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo requerido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <label className="text-muted mb-0">Precio</label>
                        <Form.Control
                          defaultValue={this.state.offertDetail.precio}
                          onChange={(e) => (frm.precio = e.target.value)}
                          className="input-black"
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo requerido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <label className="text-muted mb-0">Cantidad</label>
                        <Form.Control
                          defaultValue={this.state.offertDetail.cantidad}
                          onChange={(e) => (frm.cantidad = e.target.value)}
                          className="input-black"
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo requerido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <label>Tipo de oferta</label>
                        <Form.Control
                          required
                          defaultValue={this.state.offertDetail.tipo}
                          onChange={(e) => (frm.tipo = e.target.value)}
                          className="input-black"
                          as="select"
                        >
                          <option disabled value="">
                            Seleccionar Tipo
                          </option>
                          {this.state.types?.map((val, indx) => (
                            <option key={indx} value={val.codigo}>
                              {val.descripcion}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Campo requerido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <label className="text-muted mb-0">
                          Responsable de la oferta
                        </label>
                        <Form.Control
                          defaultValue={
                            this.state.offertDetail._responsable_?._id
                          }
                          onChange={(e) => (frm.responsable = e.target.value)}
                          className="input-black"
                          as="select"
                        >
                          <option disabled value="NA">
                            Seleccionar Responsable
                          </option>
                          {this.state.company.map((val, indx) => (
                            <option key={indx} value={val._id}>
                              {val.nombre}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Campo requerido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <label>Fecha de inicial</label>
                        <Form.Control
                          type="date"
                          defaultValue={FormatDate(
                            this.state.offertDetail.fecha_inicial,
                            "yyyy-MM-DD"
                          )}
                          onChange={(e) => (frm.fecha_inicial = e.target.value)}
                          className="input-black"
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo requerido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <label>Fecha de final</label>
                        <Form.Control
                          type="date"
                          defaultValue={FormatDate(
                            this.state.offertDetail.fecha_final,
                            "yyyy-MM-DD"
                          )}
                          onChange={(e) => (frm.fecha_final = e.target.value)}
                          className="input-black"
                        />
                        <Form.Control.Feedback type="invalid">
                          Campo requerido
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <label className="text-muted mb-0">Sucursal</label>
                    <Multiselect
                      disable={this.state.disableDdl}
                      className="text-white"
                      placeholder="Seleccionar sucursal‎‎"
                      style={this.style}
                      showCheckbox={true}
                      options={this.state.offices}
                      selectedValues={this.state.offertDetail._sucursales_}
                      onRemove={this.onRemove}
                      onSelect={this.onSelect}
                      displayValue="nombre"
                    />
                    <Form.Check
                      onChange={(e) => this.SelectAllOffice(e)}
                      label="Seleccionar todas las sucursales"
                      type={"checkbox"}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <div>
                <label className="text-muted mb-0">Imagen</label> <br />
                <img src={frm.foto} width="350" height="200" alt="product" />
              </div>
              <br />
              <Form.Group>
                <input
                  id="file-input"
                  onChange={(e) => {
                    this.UploadImage(e, frm);
                  }}
                  type="file"
                  name="name"
                  style={{ display: "none" }}
                />
                <Button className="btn-lightblue" onClick={this.ShowFileDialog}>
                  Actualizar Imagen
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      );
    }
    return content;
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="py-4 mx-auto w-75">
          {this.DetailRender()}
          <div hidden={this.props.IsAgency}>
            <h2>Total de redenciones: {this.state.data.length}</h2>
            <div className="bg-lightblack rounded-sm">
              <CustomTable
                openDialog={this.showCanjeDialog}
                perPage={10}
                items={this.state.data}
                columns={this.state.columns}
                tableType={this.state.table}
              />
            </div>
          </div>
          <ModalDialog
            Forms={Forms.CANJE}
            data={this.state.selectedRedention}
            title={this.state.title}
            openModal={this.state.isCanjeOpen}
            onClose={this.showCanjeDialog}
          />
          <ModalDialog
            Forms={Forms.OFFICESDETAIL}
            data={this.state.offertDetail?._sucursales_}
            title={this.state.titleOffice}
            openModal={this.state.isOpen}
            onClose={this.showDialog}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStatetoProp)(withRouter(ProductDetail));
