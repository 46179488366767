import React from "react";
import { RegisterReferred } from "../services/qrcode.services";
import { GetTokenAsync } from "../services/account.services";
// import encryption, { decryptQR } from "../utils/encryption.util";
import encryption from "../utils/encryption.util";

class QrCode extends React.Component {
  constructor(props) {
    super(props);

    GetTokenAsync().then((resp) => {
      console.log("resp.data.result:", resp.data.result);

      sessionStorage.setItem("token", resp.data.result.token);

      const urlParams = new URLSearchParams(window.location.search);
      var payloadUrl = urlParams.get("payload");
      var storePath = "";
      var platform = "";

      const agent = navigator.userAgent;
      if (/Android/i.test(agent)) {
        platform = "Android";
        storePath =
          "https://play.google.com/store/apps/details?id=com.afppopular&hl=es_DO";
      } else if (/iPhone|iPad|iPod/i.test(agent)) {
        platform = "IOS";
        storePath = "https://apps.apple.com/do/app/afp-popular/id1448103618";
      } else {
        platform = navigator.platform;
        storePath = "https://www.afppopular.com.do/?utm_source=clubdevida";
      }

      const info = encryption.decryptQR(payloadUrl);
      RegisterReferred({ payload: info, dispositivo: platform }).then(
        (response) => {
          if (response.data.success) {
            window.location.href = storePath;
          }
        }
      );
    });
  }

  render() {
    return null;
  }
}

export default QrCode;
