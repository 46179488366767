import { axiosServices } from "./config.services";

export async function Register(model) {
  return await axiosServices.post("/redenciones/create", model);
}

export async function Update(model) {
  return await axiosServices.post("/redensiones/update", model);
}

export async function GetAllRedention() {
  return await axiosServices.get("/redenciones/listpop");
}

export async function GetAllRedentionDashboard({ startDate, endDate, search }) {
  return await axiosServices.get(
    `/redenciones/listdashboard/${startDate}/${endDate}?search=${search}`
  );
}

export async function DownloadRedentionDashboard({
  startDate,
  endDate,
  search,
}) {
  return await axiosServices.get(
    `/redenciones/listdashboarddownload/${startDate}/${endDate}?search=${search}`,
    {
      responseType: "arraybuffer",
    }
  );
}

export async function GetByOffertId(id) {
  return await axiosServices.get(`/redenciones/findbyoferta/${id}`);
}

export async function GetById(id) {
  return await axiosServices.get(`/redensiones/find/${id}`);
}
