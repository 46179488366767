import React from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { FormatDate } from "../../utils/date.util";
import TypeProducts from "../../constants/typesproducts";

class CanjeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReadonly: true,
    };
  }

  render() {
    console.log(this.props.data);
    return (
      <>
        <div>
          <Form.Group>
            <label>Id Socio</label>
            <Form.Control
              disabled={this.state.isReadonly}
              defaultValue={this.props.data._socio_?.id_afp}
              required
              className="input-black"
            />
          </Form.Group>
          <Form.Group className="mb-1">
            <label>Fecha de Canje</label>
            <Form.Control
              disabled={this.state.isReadonly}
              defaultValue={FormatDate(this.props.data.fecha_canjeado)}
              required
              className="input-black"
            />
          </Form.Group>
          <Form.Group className="mb-1">
            <label>Canjeada</label>
            <Form.Check
              disabled={this.state.isReadonly}
              className="mx-3"
              defaultChecked={this.props.data.canjeado}
              label="Oferta Canjeada"
              type={"checkbox"}
              name={`inline-radio-1`}
            />
          </Form.Group>

          {this.props.data._productosredimidos_ ? (
            <>
              <h4 className="pt-3">Productos Redimidos</h4>

              <Row>
                <Col>
                  <label className="text-muted mb-0 pt-4">Nombre</label>
                  <p>{this.props.data.nombre}</p>
                </Col>

                <Col>
                  <label className="text-muted mb-0 pt-4">Cantidad</label>
                  <p>{this.props.data.nombre}</p>
                </Col>

                <Col>
                  <label className="text-muted mb-0 pt-4">Total</label>
                  <p>{this.props.data.nombre}</p>
                </Col>
              </Row>

              {this.props.data._productosredimidos_.map((productoRedimido) => (
                <li>
                  <Row style={{ paddingBottom: "10px" }}>
                    <Col>
                      {productoRedimido?._producto_?.nombre}
                      {productoRedimido?.adicionales?.map((adicional) => (
                        <div className="d-flex">
                          <label className="text-lightblue mr-1 mb-0">{`${
                            TypeProducts[adicional.tipo]
                          }:`}</label>
                          <p className="mb-0">{adicional.nombre}</p>
                        </div>
                      ))}
                    </Col>
                    <Col>{productoRedimido?.cantidad || 0}</Col>
                    <Col>RD${productoRedimido?.monto}</Col>
                    <hr class="hr hr-blurry" />
                  </Row>
                </li>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="text-center mt-3">
          <Button className="mx-1 btn-cancel" onClick={this.props.close}>
            Cerrar
          </Button>
        </div>
      </>
    );
  }
}

export default CanjeDetail;
