import React from "react";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Button, Row, Col } from "react-bootstrap";
import { GetOffertByType } from "../../services/offerts.services";
import { GetAllCategory } from "../../services/category.services";
// import { GetEmployeeByCompanyId } from "../../services/employee.services";
// import { Multiselect } from 'multiselect-react-dropdown';
import * as $ from "jquery";
import axios from "axios";
import swal from "sweetalert2";
import { connect } from "react-redux";
import { BlobServiceClient } from "@azure/storage-blob";
import { v4 as uuidv4 } from "uuid";

function mapStateToProps(state) {
  return state;
}

class RegisterCatalog extends React.Component {
  constructor(props) {
    super(props);

    let editData = this.props.editData;

    this.state = {
      limited: false,
      isEdit: editData.nombre === undefined ? false : true,
      nombre: editData.nombre === undefined ? "" : editData.nombre,
      descripcion:
        editData.descripcion === undefined ? "" : editData.descripcion,
      palabras_claves:
        editData.palabras_claves === undefined ? "" : editData.palabras_claves,
      foto: editData.foto === undefined ? "" : editData.foto,
      precio: editData.precio === undefined ? "" : editData.precio,
      precio_oferta:
        editData.precio_oferta === undefined ? "" : editData.precio_oferta,
      categoria: editData.categoria === undefined ? "" : editData.categoria,
      proveedor: this.props.provider,
      tipo: editData.tipo === undefined ? "individual" : editData.tipo,
      oferta: editData.oferta === undefined ? "" : editData.oferta,
      adicionales:
        editData.adicionales === undefined ? [] : editData.adicionales,
      estado: editData.estado === undefined ? "activo" : editData.estado,

      isInvalid: false,
      disableDdl: false,
      text_tag: "",

      categories: [],
      offers: [],
      types: [
        { key: "combo", value: "Combo" },
        { key: "individual", value: "Individual" },
      ],
      selectedAditional: "guarnicion",
    };

    if (editData._id) {
      this.state._id = editData._id;
      // this.SelectProvince(editData.provincia);
      // this.SelectMunicipe(editData.municipio);
      // this.SelectSector(editData.sector);
    }

    this.commit = this.Commit.bind(this);

    this.style = {
      chips: {
        background: "#3BC1D3",
      },
      searchBox: {
        background: "#1C1E24",
      },
      multiselectContainer: {
        color: "#3BC1D3",
      },
      optionContainer: {
        border: "2px solid",
      },
      option: {
        color: "#3BC1D3",
        background: "#1C1E24",
      },
    };
  }

  Commit = (event) => {
    const form = document.getElementById("frm");
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        isInvalid: true,
      });
      return;
    }

    this.props.commit(this.state);
  };

  ShowFileDialog() {
    $("#file-input").trigger("click");
  }

  FileUpload = (event) => {
    let url = `${process.env.REACT_APP_CLOUDINARYURL}${process.env.REACT_APP_CLOUDNAME}/upload`;
    const formData = new FormData();

    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_UPLOADPRESET);

      axios
        .post(url, formData)
        .then((resp) => {
          this.setState({
            foto: resp.data.secure_url,
          });
        })
        .catch((errm) => {
          swal.fire("Error", "Error al intentar cargar la imagen", "error");
        });
    }
  };

  UploadImage = (event) => {
    console.log("UploadImage:", event);

    let file = event.target.files[0];

    console.log("UploadImage:", file);

    if (!file) {
      console.log("No hay un archivo seleccionado.");
      return "";
    }

    const fileName = (uuidv4() + "_" + file.name)
      .toLowerCase()
      .replaceAll(" ", "_")
      .replaceAll("-", "_");

    console.log("UploadImage:", fileName);

    const blobServiceClient = BlobServiceClient.fromConnectionString(
      process.env.REACT_APP_AZURE_STORAGE_CONNECTION_STRING
    );

    const containerClient = blobServiceClient.getContainerClient(
      process.env.REACT_APP_AZURE_CONTAINER_NAME
    );

    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    blockBlobClient.uploadData(file).then(
      () => {
        const url = `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net/${process.env.REACT_APP_AZURE_CONTAINER_NAME}/${fileName}`;

        console.log("UploadImage:", { url });

        console.log("UploadImage:", event.target.id);

        this.setState({
          foto: url
        });
      },
      (error) => {
        console.error("Error en la carga de archivos a Azure:", error);
        swal.fire("Error", "Error al intentar cargar la imagen", "error");
      }
    );
  };

  componentDidMount() {
    GetAllCategory().then((resp) => {
      this.setState({ categories: resp.data.result });
      setTimeout(() => {
        this.setState({ categoria: this.state.categoria });
      }, 5000);
    });
    GetOffertByType("catalogo").then((resp) =>
      this.setState({ offers: resp.data.result })
    );
    this.setState({ tipo: this.state.tipo });
  }

  onSelect = (selectedList, selectedItem) => {
    let lsPlace = this.state.sucursales;
    lsPlace.push(selectedItem._id);
    this.setState({
      sucursales: lsPlace,
    });
  };

  onRemove = (selectedList, removedItem) => {
    let lsPlace = this.state.sucursales;
    let indx = lsPlace.findIndex((x) => x === removedItem._id);
    lsPlace.splice(indx, 1);
    this.setState({
      sucursales: lsPlace,
    });
  };

  SelectAllOffice(e) {
    if (e.target.checked) {
      let lsPlace = [];
      this.state.offices.forEach((x) => lsPlace.push(x._id));
      this.setState({ sucursales: lsPlace, disableDdl: true });
    } else {
      this.setState({ sucursales: [], disableDdl: false });
    }
  }

  UnlimitedQuantity(val) {
    this.setState({
      limited: val,
      cantidad: val ? 9999 : 0,
    });
  }

  removeTag = (key) => {
    const newTags = this.state.adicionales.filter((adicional) => {
      return adicional.nombre !== key;
    });
    this.setState({ adicionales: newTags });
  };

  inputKeyDown = (e) => {
    // const val = e.target.value;
    // if ((e.key === 'Enter' || typeof e.key === 'undefined') && val) {
    //     if (this.state.tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
    //         return;
    //     }
    //     this.setState({ tags: [...this.state.tags, val] });
    //     this.tagInput.value = null;
    // } else if (e.key === 'Backspace' && !val) {
    //     this.removeTag(this.state.tags.length - 1);
    // }
  };

  addTag = (selectedAditional) => {
    const val = this.state.text_tag;
    let defaultValue = false;
    if (val) {
      if (
        this.state.adicionales.find(
          (tag) =>
            tag.nombre.toLowerCase() === val.toLowerCase() &&
            tag.tipo === selectedAditional
        )
      ) {
        return;
      }
      if (!this.state.adicionales.find((tag) => tag.tipo === selectedAditional))
        defaultValue = true;

      this.setState({
        adicionales: [
          ...this.state.adicionales,
          { tipo: selectedAditional, nombre: val, defecto: defaultValue },
        ],
      });
      this.tagInput.value = null;
    }
  };

  render() {
    const { adicionales } = this.state;
    return (
      <Form
        style={{ paddingTop: "8px" }}
        id="frm"
        noValidate
        validated={this.state.isInvalid}
      >
        <Row>
          <Col md={5}>
            <div>
              <Form.Group>
                <label>Nombre</label>
                <Form.Control
                  defaultValue={this.state.nombre}
                  required
                  maxLength="75"
                  onChange={(e) => this.setState({ nombre: e.target.value })}
                  className="input-black"
                />
                {/* <textarea required  onChange={e => this.setState({ nombre: e.target.value })} className="input-black form-control mb-2" rows="4" /> */}
                <small className="text-right p-0 m-0">
                  {this.state.nombre.length} /100
                </small>
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <label>Descripción</label>
                <textarea
                  defaultValue={this.state.descripcion}
                  required
                  maxLength="140"
                  onChange={(e) =>
                    this.setState({ descripcion: e.target.value })
                  }
                  className="input-black form-control mb-2"
                  rows="3"
                />
                <small className="text-right p-0 m-0">
                  {this.state.descripcion.length} /140
                </small>
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-1">
                <label>Palabras clave</label>
                <Form.Control
                  defaultValue={this.state.palabras_claves}
                  required
                  onChange={(e) =>
                    this.setState({ palabras_claves: e.target.value })
                  }
                  className="input-black"
                />
                <small className="text-muted">
                  Separe las palabras clave por coma
                </small>
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <label>Precio regular</label>
                <Form.Control
                  defaultValue={this.state.precio}
                  required
                  placeholder="RD$0.00"
                  onChange={(e) => this.setState({ precio: e.target.value })}
                  className="input-black"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <label>Precio oferta</label>
                <Form.Control
                  defaultValue={this.state.precio_oferta}
                  required
                  placeholder="RD$0.00"
                  onChange={(e) =>
                    this.setState({ precio_oferta: e.target.value })
                  }
                  className="input-black"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <label>Tipo de producto</label>
                <Form.Control
                  required
                  value={this.state.tipo}
                  onChange={(e) => this.setState({ tipo: e.target.value })}
                  className="input-black"
                  as="select"
                >
                  <option value="" hidden="true">
                    Seleccionar tipo
                  </option>
                  <option value="" disabled="disabled" default="true">
                    Seleccionar tipo
                  </option>
                  {/* <option selected value={null} >Seleccionar tipo</option> */}
                  {this.state.types?.map((val, indx) => (
                    <option key={indx} value={val.key}>
                      {val.value}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Col>
          <Col md={7}>
            <Form.Group>
              <label>Imagen de producto</label>
              <div className="bg-dragdrop rounded-sm px-2 py-4">
                <div className="text-center">
                  <img
                    src={
                      this.state.foto ? this.state.foto : "assets/dragdrop.png"
                    }
                    width={this.state.foto ? 250 : "88"}
                    height="136"
                    alt="img"
                  />
                  <p className="m-0">
                    <small>Dimensiones recomendadas: 600 x 400 píxeles</small>
                  </p>
                </div>
                <div className="py-3 text-center">
                  <input
                    required
                    id="file-input"
                    onChange={this.UploadImage}
                    type="file"
                    name="name"
                    style={{ display: "none" }}
                  />
                  <Button
                    className="btn-lightblue"
                    onClick={this.ShowFileDialog}
                  >
                    Subir Imagen
                  </Button>
                </div>
              </div>
            </Form.Group>
            <Form.Group style={{ paddingTop: "14.5%" }} className="px-10">
              <label>Categoría</label>
              <Form.Control
                required
                value={this.state.categoria}
                onChange={(e) => this.setState({ categoria: e.target.value })}
                className="input-black"
                as="select"
              >
                <option value="" hidden="true">
                  Seleccionar Categoría
                </option>
                <option value="" disabled="disabled" default="true">
                  Seleccionar Categoría
                </option>
                {/* <option selected >Seleccionar Categoría</option> */}
                {this.state.categories?.map((val, indx) => (
                  <option key={indx} value={val._id}>
                    {val.nombre}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <label>Oferta</label>
              <Form.Control
                required
                value={this.state.oferta}
                onChange={(e) => this.setState({ oferta: e.target.value })}
                className="input-black"
                as="select"
              >
                <option value="" hidden="true">
                  Seleccionar Oferta
                </option>
                <option value="" disabled="disabled" default="true">
                  Seleccionar Oferta
                </option>
                {/* <option disabled hidden>Seleccionar Oferta</option> */}
                {this.state.offers?.map((val, indx) => (
                  <option key={indx} value={val._id}>
                    {val.nombre}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <label>Estado</label>
              <Form.Control
                required
                value={this.state.estado}
                onChange={(e) => this.setState({ estado: e.target.value })}
                className="input-black"
                as="select"
              >
                <option value="" hidden="true">
                  Seleccionar estado
                </option>
                <option value="" disabled="disabled" default="true">
                  Seleccionar estado
                </option>
                <option key={"activo"} value={"activo"}>
                  Activo
                </option>
                <option key={"inactivo"} value={"inactivo"}>
                  Inactivo
                </option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {this.state.tipo === "combo" && (
            <Col md={12}>
              <h4>Adicionales</h4>
              <Row>
                <Col md={5} className="">
                  <label>Tipo adicional</label> <br />
                  <Form.Check
                    id="guarnicion"
                    defaultChecked={true}
                    onChange={(e) =>
                      this.setState({ selectedAditional: e.target.id })
                    }
                    className="mx-3"
                    inline
                    label="Guarnición"
                    type={"radio"}
                    name={`inline-radio-1`}
                  />
                  <Form.Check
                    id="bebida"
                    onChange={(e) =>
                      this.setState({ selectedAditional: e.target.id })
                    }
                    className="mx-3"
                    inline
                    label="Bebida"
                    type={"radio"}
                    name={`inline-radio-1`}
                  />
                </Col>
                <Col className="">
                  <Form.Group>
                    <label>Nombre</label>
                    <Form.Control
                      type="text"
                      onKeyDown={this.inputKeyDown}
                      ref={(c) => {
                        this.tagInput = c;
                      }}
                      maxLength="75"
                      onChange={(e) =>
                        this.setState({ text_tag: e.target.value })
                      }
                      className="input-black"
                    />
                    <Form.Control.Feedback type="invalid">
                      Campo requerido
                    </Form.Control.Feedback>
                    <div className="d-flex flex-column text-right ">
                      <a
                        onClick={() =>
                          this.addTag(this.state.selectedAditional)
                        }
                        className="pointer btn btn-sm text-lightblue text-right"
                      >
                        + Agregar
                      </a>
                      <small className="text-muted">
                        La primera opción agregada queda por defecto.
                      </small>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="input-tag">
                <ul className="input-tag__tags">
                  {adicionales
                    .filter((tag) => {
                      return tag.tipo === this.state.selectedAditional;
                    })
                    .map((tag, i) => (
                      <li key={tag.nombre}>
                        {`${tag.nombre} ${
                          tag.defecto === true ? "(Por defecto)" : ""
                        }`}
                        <button
                          type="button"
                          onClick={() => {
                            this.removeTag(tag.nombre);
                          }}
                        >
                          +
                        </button>
                      </li>
                    ))}
                  <li className="input-tag__tags__input"> </li>
                </ul>
              </div>
            </Col>
          )}
        </Row>
        <div className="text-center py-2">
          <Button className="mx-1 btn-cancel" onClick={this.props.close}>
            Cancelar
          </Button>
          <Button className="mx-1 btn-lightblue" onClick={this.Commit}>
            Guardar
          </Button>
        </div>
      </Form>
    );
  }
}

export default connect(mapStateToProps)(RegisterCatalog);
