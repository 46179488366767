import { axiosServices } from "./config.services";

export async function RegisterCompany(model) {
  return await axiosServices.post("/empresas/create", model);
}

export async function UpdateCompany(model) {
  return await axiosServices.post("/empresas/update", model);
}

export async function GetAll() {
  return await axiosServices.get("/empresas/listpop");
}

export async function GetCompanyById(id) {
  return await axiosServices.get(`/empresas/find/${id}`);
}
