import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Button, Row, Col } from "react-bootstrap";
import { GetAllOfficeById } from "../../services/office.services";
import { GetAllTypes } from "../../services/type.services";
import { GetEmployeeByCompanyId } from "../../services/employee.services";
import { Multiselect } from "multiselect-react-dropdown";
import * as $ from "jquery";
import axios from "axios";
import swal from "sweetalert2";
import { connect } from "react-redux";
import { BlobServiceClient } from "@azure/storage-blob";
import { v4 as uuidv4 } from "uuid";

function mapStateToProps(state) {
  return state;
}

class RegisterOffert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limited: false,
      nombre: "",
      descripcion: "",
      palabras_claves: "",
      foto: "",
      precio: "",
      multiple: false,
      cantidad: 0,
      proveedor: this.props.provider,
      sucursales: [],
      types: [],
      responsable: "",
      tipo: "descuento",
      fecha_inicial: "",
      fecha_final: "",
      estado: "activo",
      offices: [],
      company: [],
      isInvalid: false,
      disableDdl: false,
    };

    this.commit = this.Commit.bind(this);

    this.style = {
      chips: {
        background: "#3BC1D3",
      },
      searchBox: {
        background: "#1C1E24",
      },
      multiselectContainer: {
        color: "#3BC1D3",
      },
      optionContainer: {
        border: "2px solid",
      },
      option: {
        color: "#3BC1D3",
        background: "#1C1E24",
      },
    };
  }

  Commit = (event) => {
    const form = document.getElementById("frm");
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        isInvalid: true,
      });
      return;
    }

    this.props.commit(this.state);
  };

  ShowFileDialog() {
    $("#file-input").trigger("click");
  }

  FileUpload = (event) => {
    let url = `${process.env.REACT_APP_CLOUDINARYURL}${process.env.REACT_APP_CLOUDNAME}/upload`;
    const formData = new FormData();

    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_UPLOADPRESET);

      axios
        .post(url, formData)
        .then((resp) => {
          this.setState({
            foto: resp.data.secure_url,
          });
        })
        .catch((errm) => {
          swal.fire("Error", "Error al intentar cargar la imagen", "error");
        });
    }
  };

  UploadImage = (event) => {
    console.log("UploadImage:", event);

    let file = event.target.files[0];

    console.log("UploadImage:", file);

    if (!file) {
      console.log("No hay un archivo seleccionado.");
      return "";
    }

    const fileName = (uuidv4() + "_" + file.name)
      .toLowerCase()
      .replaceAll(" ", "_")
      .replaceAll("-", "_");

    console.log("UploadImage:", fileName);

    const blobServiceClient = BlobServiceClient.fromConnectionString(
      process.env.REACT_APP_AZURE_STORAGE_CONNECTION_STRING
    );

    const containerClient = blobServiceClient.getContainerClient(
      process.env.REACT_APP_AZURE_CONTAINER_NAME
    );

    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    blockBlobClient.uploadData(file).then(
      () => {
        const url = `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net/${process.env.REACT_APP_AZURE_CONTAINER_NAME}/${fileName}`;

        console.log("UploadImage:", { url });

        console.log("UploadImage:", event.target.id);

        this.setState({
          foto: url
        });
      },
      (error) => {
        console.error("Error en la carga de archivos a Azure:", error);
        swal.fire("Error", "Error al intentar cargar la imagen", "error");
      }
    );
  };

  componentDidMount() {
    GetAllOfficeById(this.props.empresa._id).then((resp) =>
      this.setState({ offices: resp.data.result })
    );
    GetEmployeeByCompanyId(this.props.empresa._id).then((resp) =>
      this.setState({ company: resp.data.result })
    );
    GetAllTypes().then((resp) => this.setState({ types: resp.data.result }));
  }

  onSelect = (selectedList, selectedItem) => {
    let lsPlace = this.state.sucursales;
    lsPlace.push(selectedItem._id);
    this.setState({
      sucursales: lsPlace,
    });
  };

  onRemove = (selectedList, removedItem) => {
    let lsPlace = this.state.sucursales;
    let indx = lsPlace.findIndex((x) => x === removedItem._id);
    lsPlace.splice(indx, 1);
    this.setState({
      sucursales: lsPlace,
    });
  };

  SelectAllOffice(e) {
    if (e.target.checked) {
      let lsPlace = [];
      this.state.offices.forEach((x) => lsPlace.push(x._id));
      this.setState({ sucursales: lsPlace, disableDdl: true });
    } else {
      this.setState({ sucursales: [], disableDdl: false });
    }
  }

  UnlimitedQuantity(val) {
    this.setState({
      limited: val,
      cantidad: val ? 9999 : 0,
    });
  }

  render() {
    return (
      <Form id="frm" noValidate validated={this.state.isInvalid}>
        <Row>
          <Col md={5}>
            <div>
              <Form.Group>
                <label>Nombre</label>
                <textarea
                  required
                  maxLength="140"
                  onChange={(e) => this.setState({ nombre: e.target.value })}
                  className="input-black form-control mb-2"
                  rows="4"
                />
                <small className="text-right p-0 m-0">
                  {this.state.nombre.length} /140
                </small>
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <label>Descripción</label>
                <textarea
                  required
                  onChange={(e) =>
                    this.setState({ descripcion: e.target.value })
                  }
                  className="input-black form-control mb-2"
                  rows="3"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <label>Tipo de oferta</label>
                <Form.Control
                  required
                  defaultValue=""
                  onChange={(e) => this.setState({ tipos: e.target.value })}
                  className="input-black"
                  as="select"
                >
                  <option disabled value="">
                    Seleccionar Tipo
                  </option>
                  {this.state.types?.map((val, indx) => (
                    <option key={indx} value={val.codigo}>
                      {val.descripcion}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-1">
                <label>Palabras clave</label>
                <Form.Control
                  required
                  onChange={(e) =>
                    this.setState({ palabras_claves: e.target.value })
                  }
                  className="input-black"
                />
                <small className="text-muted">
                  Separe las palabras clave por coma.
                </small>
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="pt-2">
                <label>Cantidad mínima de días</label>
                <Form.Control
                  required
                  onChange={(e) =>
                    this.setState({ cantidad_dias_minimos: e.target.value })
                  }
                  className="input-black"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <label>Precio</label>
                <Form.Control
                  required
                  placeholder="RD$0.00"
                  onChange={(e) => this.setState({ precio: e.target.value })}
                  className="input-black"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <label>Fecha inicial</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  selected={this.state.fecha_inicial}
                  onChange={(date) => this.setState({ fecha_inicial: date })}
                  className="input-black form-control"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Col>
          <Col md={7}>
            <Form.Group>
              <label>Imagen de oferta</label>
              <div className="bg-dragdrop rounded-sm px-2 py-4">
                <div className="text-center">
                  <img
                    src={
                      this.state.foto ? this.state.foto : "assets/dragdrop.png"
                    }
                    width={this.state.foto ? 250 : "88"}
                    height="136"
                    alt="img"
                  />
                  <p className="m-0">
                    <small>Dimensiones recomendadas: 600 x 400 píxeles</small>
                  </p>
                </div>
                <div className="py-3 text-center">
                  <input
                    id="file-input"
                    onChange={this.UploadImage}
                    type="file"
                    name="name"
                    style={{ display: "none" }}
                  />
                  <Button
                    className="btn-lightblue"
                    onClick={this.ShowFileDialog}
                  >
                    Subir Imagen
                  </Button>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="pt-0 pb-3">
              <label>Cantidad mínima de ofertas</label>
              <Form.Control
                type="number"
                required
                onChange={(e) =>
                  this.setState({ cantidad_ofertas_minimo: e.target.value })
                }
                className="input-black"
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="pt-1">
              <Row>
                <Col md={4} className="px-0">
                  <label>Compra múltiple</label> <br />
                  <Form.Check
                    onChange={(e) =>
                      this.setState({
                        multiple: e.target.value === "on" ? true : false,
                      })
                    }
                    className="mx-3"
                    inline
                    label="Si"
                    type={"radio"}
                    name={`inline-radio-1`}
                  />
                  <Form.Check
                    defaultChecked={true}
                    onChange={(e) =>
                      this.setState({ multiple: e.target.value })
                    }
                    className="mx-3"
                    inline
                    label="No"
                    type={"radio"}
                    name={`inline-radio-1`}
                  />
                </Col>
                <Col md={2} className="px-0">
                  <label>Ilimitada</label> <br />
                  <Form.Check
                    onChange={(e) => this.UnlimitedQuantity(e.target.checked)}
                    className="mx-3"
                    inline
                    label="Si"
                    type={"checkbox"}
                    name={`inline-radio-1`}
                  />
                </Col>

                <Col md={6}>
                  <label>Cantidad total en inventario</label>
                  <Form.Control
                    type="text"
                    disabled={this.state.limited}
                    value={this.state.cantidad}
                    required
                    onChange={(e) =>
                      this.setState({ cantidad: e.target.value })
                    }
                    className="input-black"
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <label>Responsable de oferta</label>
              <Form.Control
                required
                defaultValue=""
                onChange={(e) => this.setState({ responsable: e.target.value })}
                className="input-black"
                as="select"
              >
                <option disabled value="">
                  Seleccionar Responsable
                </option>
                {this.state.company?.map((val, indx) => (
                  <option key={indx} value={val._id}>
                    {val.nombre}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <label>Fecha final</label> <br />
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/yyyy"
                selected={this.state.fecha_final}
                onChange={(date) => this.setState({ fecha_final: date })}
                className="input-black form-control"
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group disabled>
              <label>Sucursal</label>
              <Multiselect
                disable={this.state.disableDdl}
                className="text-white"
                placeholder="Seleccionar sucursal‎‎"
                style={this.style}
                showCheckbox={true}
                options={this.state.offices}
                onRemove={this.onRemove}
                onSelect={this.onSelect}
                displayValue="nombre"
              />
              <Form.Check
                onChange={(e) => this.SelectAllOffice(e)}
                label="selecionar todas las sucursales"
                type={"checkbox"}
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="text-center py-2">
          <Button className="mx-1 btn-cancel" onClick={this.props.close}>
            Cancelar
          </Button>
          <Button className="mx-1 btn-lightblue" onClick={this.Commit}>
            Guardar
          </Button>
        </div>
      </Form>
    );
  }
}

export default connect(mapStateToProps)(RegisterOffert);
