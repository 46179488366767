import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";

import Navbar from "../components/navbar/navbar";
import Card from "../components/cards/cards";
import Chart from "../components/charts/customcharts";
import table from "../constants/tables.enum";
import {
  GetAllOffertById,
  RegisterOffert,
  DashboardOfferts,
  UpdateOffert,
} from "../services/offerts.services";
import Swal from "sweetalert2";
import moment from "moment";
import { CurrencyFormat } from "../utils/currency.util";
import { DiffDate } from "../utils/date.util";
import swal from "sweetalert2";
import { store } from "../app/store";
import CustomTable from "../components/customtable/customtable";
import { GetAllRedentionDashboard } from "../services/redentions.services";
// import Forms from "../constants/forms.enum";
// import ModalDialog from "../components/dialog/modaldialog";
const ENTER_KEY = 13;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      title: "Nueva oferta",
      data: [],
      dataRedemptions: [],
      labels: [],
      redentionqty: [],
      amount: [],
      columns: [
        "Título",
        "Descripción",
        "Imagen",
        "Fecha",
        "Duración",
        "Acciones",
      ],
      columnsRedemptions: ["Oferta", "Sucursal", "Estado", "Fecha", "Hora"],
      startDate: moment().startOf("year").toDate(),
      endDate: moment().endOf("year").toDate(),
      search: "",
      table: table.OFFERTS,
      tableRedemption: table.REDEMPTIONSDASHBOARD,
    };

    this.showDialog = this.ToggleModal.bind(this);
    this.select = this.SelectedOffert.bind(this);
    this.save = this.SaveChange.bind(this);
    this.delete = this.Remove.bind(this);
    this.verifyUser = this.verifyUser.bind(this);
  }

  async SaveChange(states) {
    let diffDay = DiffDate(states.fecha_inicial, states.fecha_final);
    if (diffDay < 0) {
      swal.fire(
        "Advertencia",
        "La fecha inicial no puede ser superior a la final.",
        "warning"
      );
      return;
    }

    if (diffDay < states.cantidad_dias_minimos) {
      swal.fire(
        "Advertencia",
        `La cantidad minima de dias es ${states.cantidad_dias_minimos} y se seleccionaron ${diffDay}`,
        "warning"
      );
      return;
    }

    let min = parseInt(states.cantidad_ofertas_minimo);
    let qty = parseInt(states.cantidad);

    if (qty < min) {
      swal.fire(
        "Advertencia",
        `La cantidad minima de ofertas es ${states.cantidad_ofertas_minimo} y se agregaron ${states.cantidad}`,
        "warning"
      );
      return;
    }

    let params = {
      nombre: states.nombre,
      descripcion: states.descripcion,
      palabras_claves: states.palabras_claves,
      foto: states.foto,
      precio: states.precio,
      multiple: states.multiple,
      cantidad: states.cantidad,
      proveedor: states.proveedor,
      sucursales: states.sucursales,
      responsable: states.responsable,
      cantidad_ofertas_minimo: states.cantidad_ofertas_minimo,
      cantidad_dias_minimos: states.cantidad_dias_minimos,
      tipo: states.tipo,
      fecha_inicial: states.fecha_inicial,
      fecha_final: states.fecha_final,
      estado: states.estado,
    };

    await RegisterOffert(params).then(async (resp) => {
      Swal.fire("Información", resp.data.message, "success");
      GetAllOffertById(this.props.provider).then((resp) => {
        let items = resp.data.result.sort(
          (a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion)
        );
        this.setState({ data: items });
      });
      this.ToggleModal();
    });
  }

  SelectedOffert(item) {
    this.props.history.push(`/orderdetail/${item._id}`);
  }

  ToggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  verifyUser = () => {
    const { userRole } = this.props;

    if (userRole === "rol_cajero") {
      this.props.history.push("/retentions/exchange-offers");
      return;
    }
  };

  componentDidMount() {
    try {
      let startDate = moment().startOf("year").format("YYYY-MM-DD");
      let endDate = moment().endOf("year").format("YYYY-MM-DD");
      this.verifyUser();

      GetAllOffertById(this.props.provider).then((resp) => {
        let items = resp.data.result.sort(
          (a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion)
        );
        this.setState({ data: items });
      });

      GetAllRedentionDashboard({
        startDate: this.state.startDate || startDate,
        endDate: this.state.endDate || endDate,
        search: this.state.search,
      }).then((resp) => {
        this.setState({ dataRedemptions: resp.data.result });
      });

      let proveedor_id = this.props.provider;

      if (this.props.userRole === "rol_empresa") {
        DashboardOfferts({ startDate, endDate, proveedor_id }).then((resp) => {
          let result = resp.data.result;
          let amount = [];
          let quantity = [];
          let labels = [];

          result.reporte.forEach((element) => {
            amount.push(element.monto);
            quantity.push(element.cantidad);
            labels.push(element.mes);
          });

          this.setState({
            amount: amount,
            redentionqty: quantity,
            labels: labels,
          });

          let payload = {
            ofertascreadas: result.cantidad_ofertas_creadas,
            ofertasactivas: result.cantidad_ofertas_activas,
            ofertascanjeadas: result.cantidad_ofertas_canjeadas,
            ofertasmontocanjeadas: result.monto_ofertas_canjeadas,
            ofertasavencer: result.cantidad_ofertas_por_vencer,
          };

          store.dispatch({ type: "SET_REPORT", payload });
        });
      }
    } catch (e) {
      console.log("e", e);
      Swal.fire("¡Error!", "No se pudo obtener las ofertas.", "error");
    }
  }

  Remove(item) {
    item.estado = "borrado";
    UpdateOffert(item).then((resp) => {
      Swal.fire("Información", resp.data.message, "success");
      GetAllOffertById(this.props.provider).then((resp) => {
        let items = resp.data.result.sort(
          (a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion)
        );
        this.setState({ data: items });
      });
    });
  }

  onChangeDate = (dates) => {
    const [start, end] = dates;
    this.setState({ startDate: start, endDate: end });

    if (start && end) {
      setTimeout(() => {
        GetAllRedentionDashboard({
          startDate: moment(this.state?.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.state?.endDate).format("YYYY-MM-DD"),
          search: this.state.search,
        }).then((resp) => {
          this.setState({ dataRedemptions: resp.data.result });
        });
      }, 1000);
    }
  };

  handleSearch = (e) => {
    this.setState({ search: e?.target?.value });
    // if (e.target.value.length > 1) {
    setTimeout(() => {
      GetAllRedentionDashboard({
        startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
        search: this.state.search,
      }).then((resp) => {
        this.setState({ dataRedemptions: resp.data.result });
      });
    }, 1000);
    // }
  };

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
    }
  }

  render() {
    const canViewCharts =
      this.props.userRole === "rol_empresa" ||
      this.props.userRole === "rol_afp_agencia";
    return (
      <>
        <Navbar />
        {this.props.userRole !== "rol_agencia" && (
          <div
            className="retention-anchor type-2"
            onClick={() =>
              this.props.history.push("/retentions/exchange-offers")
            }
          >
            <div className="container-fluid">
              <p className="mt-3">Canjear Ofertas</p>
              <img src="/assets/icons/exchange.png" alt="exchange-offers" />
            </div>
          </div>
        )}
        <h2 className="text-center text-md-left text-lg-left mt-5 mb-4 ml-4 ml-xs-0">
          Ofertas
        </h2>
        <div className="retention-offers">
          <Row>
            <Col xs={6} md={4} lg>
              <Card
                title={"Ofertas creadas"}
                content={this.props.ofertascreadas}
              />
            </Col>
            <Col xs={6} md={4} lg>
              <Card
                title={"Ofertas activas"}
                content={this.props.ofertasactivas}
              />
            </Col>
            <Col hidden={this.props.IsAgency}>
              <Card
                title={"Ofertas canjeadas"}
                content={this.props.ofertascanjeadas}
              />
            </Col>
            <Col xs={6} md={4} lg>
              <Card
                title={"Ofertas por vencer"}
                content={this.props.ofertasavencer}
              />
            </Col>
            <Col hidden={this.props.IsAgency}>
              <Card
                title={"Equivalente en pesos"}
                content={CurrencyFormat(
                  this.props.ofertasmontocanjeadas.toString()
                )}
              />
            </Col>
          </Row>

          {canViewCharts && (
            <Row>
              <Col lg={12}>
                <Chart
                  labels={this.state.labels}
                  data={this.state.redentionqty}
                  chartType="line"
                />
              </Col>
              <Col lg={12}>
                <Chart
                  labels={this.state.labels}
                  data={this.state.amount}
                  chartType="bar"
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12}>
              <h2 className="mt-5">Lista de redenciones</h2>
            </Col>
          </Row>
          <Row className="mb-1">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginRight: "18px",
              }}
            >
              <Col
                lg={2}
                xs={6}
                sm={2}
                xl={2}
                style={{
                  position: "relative",
                }}
              >
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  startDate={this.state.startDate || null}
                  endDate={this.state.endDate || null}
                  selectsRange={true}
                  // isClearable={this.state.startDate && this.state.endDate}
                  onChange={this.onChangeDate}
                  placeholderText="Fecha"
                  className="input-black form-control"
                />
                {/* {(!this.state.startDate && !this.state.endDate) && */}
                <img
                  style={{
                    position: "absolute",
                    color: "#3BC1D3",
                    right: 20,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  src="/assets/icons/calendar.png"
                  alt="calendar-redemptioms"
                />
                {/* } */}
              </Col>
              <InputGroup
                onChange={this.handleSearch}
                onKeyDown={this.handleKeyDown}
                size="5"
                style={{ width: "20%", position: "relative" }}
              >
                <FormControl
                  className="input-black"
                  type="text"
                  placeholder="Buscar..."
                />
                <div
                  style={{
                    position: "absolute",
                    right: 8,
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#3BC1D3",
                  }}
                  className="search-icon"
                >
                  <i class="fas fa-search" />
                </div>
              </InputGroup>
            </div>

            <Col lg={12}>
              <CustomTable
                delete={this.delete}
                perPage={5}
                edit={this.select}
                items={this.state.dataRedemptions}
                columns={this.state.columnsRedemptions}
                tableType={this.state.tableRedemption}
                filters={{
                  startDate: this.state.startDate,
                  endDate: this.state.endDate,
                  search: this.state.search,
                }}
                export
                // exportEndpoin
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <h2 className="mt-2">Lista de ofertas</h2>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col lg={12}>
              <CustomTable
                delete={this.delete}
                perPage={5}
                edit={this.select}
                items={this.state.data}
                columns={this.state.columns}
                tableType={this.state.table}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default connect((state) => state)(withRouter(Dashboard));
