import React from "react";
import { Modal } from "react-bootstrap";
import RegisterOffert from "../dialogforms/registeroffert";
import RegisterOffice from "../dialogforms/registeroffice";
import RegisterCatalog from "../dialogforms/registercatalog";
import RegisterCategory from "../dialogforms/registercategory";
import RegisterResponsable from "../dialogforms/registerresponsable";
import OfficeDetail from "../dialogforms/officedetail";
import CanjeDetail from "../dialogforms/canjedetail";
import CatalogDetail from "../dialogforms/catalogdetail";
import Forms from "../../constants/forms.enum";
import Info from "../dialogforms/info";

export default class ModalDialog extends React.Component {
  bindContent() {
    switch (this.props.Forms) {
      case Forms.OFFERTS:
        return (
          <RegisterOffert
            editData={this.props.editData}
            commit={this.props.SaveChange}
            close={this.props.onClose}
          />
        );

      case Forms.OFFICES:
        return (
          <RegisterOffice
            editData={this.props.editData}
            commit={this.props.SaveChange}
            close={this.props.onClose}
          />
        );

      case Forms.CATALOG:
        return (
          <RegisterCatalog
            editData={this.props.editData}
            commit={this.props.SaveChange}
            close={this.props.onClose}
          />
        );

      case Forms.CATEGORY:
        return (
          <RegisterCategory
            editData={this.props.editData}
            commit={this.props.SaveChange}
            close={this.props.onClose}
          />
        );

      case Forms.RESPONSABLE:
        return (
          <RegisterResponsable
            editData={this.props.editData}
            commit={this.props.SaveChange}
            close={this.props.onClose}
          />
        );

      case Forms.OFFICESDETAIL:
        return (
          <OfficeDetail close={this.props.onClose} items={this.props.data} />
        );

      case Forms.CANJE:
        return (
          <CanjeDetail close={this.props.onClose} data={this.props.data} />
        );

      case Forms.CATALOGDETAIL:
        return (
          <CatalogDetail
            close={this.props.onClose}
            data={this.props.editData}
          />
        );

      case Forms.INFO:
        return (
          <Info
            close={this.props.onClose}
            commit={this.props.SaveChange}
            data={this.props.data}
          />
        );

      default:
        break;
    }
  }
  render() {
    return (
      <div>
        <Modal centered size="lg" show={this.props.openModal}>
          <Modal.Body className="px-4 bg-secundaryblack rounded border-0">
            <Modal.Title className=" pt-3 pl-2">{this.props.title}</Modal.Title>
            {this.bindContent()}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
