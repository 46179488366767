var CryptoJS = require("crypto-js");

exports.encrypt = function (data) {
  var datacifrada = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return datacifrada;
};

exports.decrypt = function (datacifrada) {
  var databytes = CryptoJS.AES.decrypt(datacifrada, process.env.REACT_APP_SECRET_KEY);
  var datadescifrada = JSON.parse(databytes.toString(CryptoJS.enc.Utf8));
  return datadescifrada;
};

exports.encryptsha = function (data) {
  var datacifrada = CryptoJS.SHA512(JSON.stringify(data)).toString();
  return datacifrada;
};

exports.encryptQR = function (data) {
  var datacifrada = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY_QR
  ).toString();
  return datacifrada;
};

exports.decryptQR = function (datacifrada) {
  var databytes = CryptoJS.AES.decrypt(datacifrada, process.env.REACT_APP_SECRET_KEY_QR);
  var datadescifrada = JSON.parse(databytes.toString(CryptoJS.enc.Utf8));
  return datadescifrada;
};
