import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import {
  Signin,
  SigninAgency,
  AsignProvider,
} from "../services/account.services";
import validation from "../constants/validation.patterns";
import { store } from "../app/store";
import Swal from "sweetalert2";

function mapStatetoProp(state) {
  return state;
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isagency: false,
      isInvalid: false,
      isLoading: true,
      pwdvalidation: "",
      providers: [],
      selectedcompany: false,
      providerId: "",
      companyId: "",
    };

    this.createUserRole.bind(this);
  }

  componentDidMount() {
    store.dispatch({ type: "LOGOFF" });
  }

  createUserRole = (store, super_usuario, isAgency) => {
    let role = "rol_empresa";
    let defaultRoute = "/dashboard";

    switch (true) {
      case super_usuario === false && isAgency === false:
        role = "rol_cajero";
        defaultRoute = "/retentions/exchange-offers";
        break;

      case super_usuario === true && isAgency === true:
        role = "rol_afp_agencia";
        break;

      case super_usuario === true && isAgency === false:
        role = "rol_empresa";
        defaultRoute = "/retentions/access-chooser";
        break;

      case super_usuario === false && isAgency === true:
        role = "rol_agencia";
        break;

      default:
        break;
    }

    store.dispatch({ type: "SET_ROLE", payload: { userRole: role } });

    return defaultRoute;
  };

  handleSubmit = async (event) => {
    const form = document.getElementById("frm");

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        isInvalid: true,
      });
    }

    if (!this.state.password) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        isInvalid: true,
        pwdvalidation: "Campo requerido",
      });
      return;
    }

    let reg = new RegExp(validation.PASSWORD);

    if (
      process.env.NODE_ENV === "production" &&
      !reg.test(this.state.password)
    ) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        isInvalid: true,
        pwdvalidation:
          "La contraseña no cuenta con la estrucutra de seguridad recomendada",
      });
      return;
    }

    if (this.state.isagency) {
      await SigninAgency(this.state.username, this.state.password)
        .then((resp) => {
          let company = [];
          store.dispatch({ type: "SET_PROFILE", payload: resp.data.result });
          resp.data.result._proveedores_.forEach((x) => company.push(x));
          this.setState({ providers: company });
          sessionStorage.setItem("username", this.state.username);
          sessionStorage.setItem("usertoken", resp.data.result.token);
          sessionStorage.setItem("rolToken", resp.data.result.rolToken);

          this.createUserRole(
            store,
            resp.data.result.super_usuario,
            this.state.isagency
          );
        })
        .catch((errm) => {
          Swal.fire(
            "Advertencia",
            "Usuario o contraseña incorrecta",
            "warning"
          );
        });
    } else {
      await Signin(this.state.username, this.state.password)
        .then((resp) => {
          let provider = {
            id: resp.data.result._proveedor_.id,
            IsAgency: false,
          };
          store.dispatch({ type: "SET_PROVIDER", payload: provider });
          store.dispatch({ type: "SET_PROFILE", payload: resp.data.result });
          store.dispatch({
            type: "SET_COMPANY",
            payload: resp.data.result._empresa_,
          });
          sessionStorage.setItem("username", this.state.username);
          sessionStorage.setItem("usertoken", resp.data.result.token);
          sessionStorage.setItem("rolToken", resp.data.result.rolToken);

          const redirectUrl = this.createUserRole(
            store,
            resp.data.result.super_usuario,
            this.state.isagency
          );

          // if (!super_usuario && !IsAgency) {
          //   //Usuario Cajero
          //   defaultRoute = "/retentions/exchange-offers";
          // } else if (super_usuario && !IsAgency) {
          //   //Super usuario y no agencia
          //   defaultRoute = "/retentions/access-chooser";
          // }

          this.props.history.push(redirectUrl);
        })
        .catch((errm) => {
          Swal.fire(
            "Advertencia",
            "Usuario o contraseña incorrecta",
            "warning"
          );
        });
    }
  };

  selectcompany = (indx) => {
    let data = this.state.providers[indx];
    let provider = { id: data._id, IsAgency: true };
    store.dispatch({ type: "SET_COMPANY", payload: data.empresa });
    store.dispatch({ type: "SET_PROVIDER", payload: provider });
    this.setState({
      selectedcompany: true,
      providerId: data._id,
      companyId: data.empresa._id,
    });
  };

  next = async () => {
    if (this.state.selectedcompany) {
      await AsignProvider(this.state.providerId, this.state.companyId);
      const { super_usuario, IsAgency } = this.props;
      let defaultRoute = "/dashboard";

      if (!super_usuario && !IsAgency) {
        defaultRoute = "/retentions/exchange-offers";
      } else if (super_usuario) {
        defaultRoute = "/retentions/access-chooser";
      }

      this.props.history.push(defaultRoute);
    } else {
      Swal.fire(
        "Advertencia",
        "Debe seleccionar una empresa antes de continuar",
        "warning"
      );
    }
  };

  render() {
    return (
      <div className="mx-auto col-md-8 col-lg-4 login-panel">
        <div>
          <div className="my-3 text-center">
            <img width="200" height="50" src="/assets/logo.png" alt="logo" />
          </div>
          <Form
            id="frm"
            className="px-5"
            noValidate
            validated={this.state.isInvalid}
          >
            <Form.Group>
              <label className="text-white">Correo</label>
              <Form.Control
                autoComplete="off"
                onChange={(e) => this.setState({ username: e.target.value })}
                type="text"
                placeholder="usuario"
                required
                className="input-black"
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <label className="text-white">Password</label>
              <Form.Control
                autoComplete="off"
                onChange={(e) => this.setState({ password: e.target.value })}
                pattern={validation.PASSWORD}
                type="password"
                placeholder="contraseña"
                required
                className="input-black"
              />
              <Form.Control.Feedback type="invalid">
                {this.state.pwdvalidation}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Check
                onChange={() =>
                  this.setState({ isagency: !this.state.isagency })
                }
                type="switch"
                id="custom-switch bg-lightblue"
                label="Es una agencia"
              />
            </Form.Group>
          </Form>
          <div className="px-5">
            <div
              hidden={this.state.providers.length !== 0}
              className="text-center"
            >
              <Button
                onClick={this.handleSubmit}
                className="btn-block btn-lightblue"
              >
                Iniciar Sesión
              </Button>
            </div>
            <Form hidden={this.state.providers.length === 0}>
              <label>Empresa a administrar</label>
              <Form.Control
                as="select"
                defaultValue={"NA"}
                onChange={(e) => this.selectcompany(e.target.value)}
                className="input-black"
              >
                <option disabled value="NA">
                  Seleccione la Empresa
                </option>
                {this.state.providers
                  ?.sort((a, b) => {
                    if (a?.empresa.nombre < b?.empresa.nombre) {
                      return -1;
                    }
                    if (a?.empresa.nombre > b?.empresa.nombre) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((val, indx) => (
                    <option key={indx} value={indx}>
                      {val.empresa?.nombre}
                    </option>
                  ))}
              </Form.Control>
              <div className="text-center pt-2">
                <Button onClick={this.next} className="btn-block btn-lightblue">
                  Continuar
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStatetoProp)(withRouter(Login));
