let INITIALDATA = {
  ofertascreadas: 0,
  ofertasactivas: 0,
  ofertascanjeadas: 0,
  ofertasmontocanjeadas: 0,
  ofertasavencer: 0,
  empresa: {},
  provider: "",
  isLogin: false,
  IsAgency: false,
  userRole: "",
};

function account(state = INITIALDATA, actions) {
  switch (actions.type) {
    case "SET_PROFILE": {
      state.isLogin = true;
      return { ...state, ...actions.payload };
    }
    case "SET_REPORT": {
      return { ...state, ...actions.payload };
    }
    case "SET_COMPANY": {
      state.empresa = actions.payload;
      return { ...state };
    }
    case "SET_PROVIDER": {
      state.IsAgency = actions.payload.IsAgency;
      state.provider = actions.payload.id;
      return { ...state };
    }

    case "SET_ROLE": {
      return { ...state, userRole: actions.payload.userRole };
    }

    case "LOGOFF": {
      state = INITIALDATA;
      return { ...state };
    }

    default:
      return state;
  }
}
export default account;
