import { axiosServices } from "./config.services";

export async function RegisterCategory(model) {
  return await axiosServices.post("/categoriasproductos/create", model);
}

export async function UpdateCategory(model) {
  return await axiosServices.put("/categoriasproductos/update", model);
}

export async function GetAllCategory() {
  return await axiosServices.get("/categoriasproductos/listpop");
}

export async function GetCategoryById(id) {
  return await axiosServices.get(`/categoriasproductos/find/${id}`);
}
