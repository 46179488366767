import { axiosServices } from "./config.services";

export async function RegisterCatalog(model) {
  return await axiosServices.post("/productos/create", model);
}

export async function UpdateCatalog(model) {
  return await axiosServices.put("/productos/update", model);
}

export async function GetAllCatalog() {
  return await axiosServices.get("/productos/listpop");
}

export async function GetEmployeeByCompanyId(id) {
  return await axiosServices.get(`/productos/listaempleadosporempresa/${id}`);
}

export async function GetCatalogById(id) {
  return await axiosServices.get(`/productos/find/${id}`);
}
