import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  Button,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { connect } from "react-redux";

import Navbar from "../components/navbar/navbar";
import Card from "../components/cards/cards";
import CustomTable from "../components/customtable/customtable";
import table from "../constants/tables.enum";
import {
  GetAllOffertById,
  RegisterOffert,
  UpdateOffert,
} from "../services/offerts.services";
import {
  GetAllOfficeById,
  RegisterOffice,
  UpdateOffice,
} from "../services/office.services";
import {
  GetEmployeeByCompanyId,
  RegisterEmployee,
  UpdateEmployee,
} from "../services/employee.services";
import {
  GetAllCatalog,
  RegisterCatalog,
  UpdateCatalog,
} from "../services/catalog.services";

import {
  RegisterCategory,
  GetAllCategory,
  UpdateCategory,
} from "../services/category.services";

import { GetCompanyById, UpdateCompany } from "../services/company.services";
import ModalDialog from "../components/dialog/modaldialog";
import Forms from "../constants/forms.enum";
import {
  GetProvinces,
  GetMunicipies,
  GetSectors,
} from "../services/location.services";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import { CurrencyFormat } from "../utils/currency.util";
import * as $ from "jquery";
import axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import { v4 as uuidv4 } from "uuid";

function mapStatetoProp(state) {
  return state;
}

let hasAccessCatalog = false;
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataFilter: [],
      columns: [],
      table: null,
      company: {},
      isOpen: false,
      btnLabel: "",
      btnLabelEdit: "",
      inputSearchLabel: "",
      forms: Forms.OFFERTS,
      modelData: {},
      isEdit: false,
      editProfile: false,
      isView: false,
      provincias: [],
      municipios: [],
      sectores: [],
      provincia: "0",
      municipio: "0",
      sector: "0",
      profileLogo: "",
      brandLogo: "",
    };

    this.changeTab = this.toggleTab.bind(this);
    this.select = this.Selected.bind(this);
    this.save = this.SaveChange.bind(this);
    this.showDialog = this.ToggleModal.bind(this);
    this.view = this.View.bind(this);
    this.delete = this.Remove.bind(this);
    this.saveprofile = this.ProfileSave.bind(this);
  }

  ProfileContent() {
    let content = "";
    let frm = { ...this.state.company };

    if (!this.state.editProfile) {
      content = (
        <div>
          <Row className="mb-4">
            <Col md={2} className="pr-0">
              <span
                onClick={() => {
                  this.props.history.push(`/dashboard`);
                }}
                className="pointer text-lightblue mx-2"
              >
                <i className="fas fa-2x fa-chevron-left"></i>
              </span>
              <img
                src={this.state.company.logo}
                width="80"
                height="80"
                alt="brandlogo"
              />
            </Col>
            <Col md={8}>
              <h3 className="mb-0 mt-2">{this.state.company.nombre}</h3>
              {this.state.company.slogan}
            </Col>
            <Col md={2} className="text-right pt-3">
              <Button
                onClick={() => this.enableEdit()}
                className="btn-lightblue"
              >
                Editar
              </Button>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="pl-0">
              <Form.Group className="mb-0 px-3">
                <label className="text-muted mb-0">Descripción</label>
                <p>{this.state.company.descripcion}</p>
              </Form.Group>

              <Form.Group className="mb-0 px-3">
                <label className="text-muted mb-0">Teléfono</label>
                <p>{this.state.company.telefono}</p>
              </Form.Group>

              <Form.Group className="mb-0 px-3">
                <label className="text-muted mb-0">WhatsApp</label>
                <p>{this.state.company.whatsapp}</p>
              </Form.Group>

              <Form.Group className="mb-0 px-3">
                <label className="text-muted mb-0">Contacto</label>
                <p>{this.state.company._contacto_?.nombre}</p>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-0">
                <label className="text-muted mb-0">Dirección</label>
                <p>{this.state.company.direccion}</p>
              </Form.Group>

              <Form.Group className="mb-0">
                <label className="text-muted mb-0">Provincia</label>
                <p>{this.state.company._provincia_?.provincia}</p>
              </Form.Group>

              <Form.Group className="mb-0">
                <label className="text-muted mb-0">Municipio</label>
                <p>{this.state.company._municipio_?.minicipio}</p>
              </Form.Group>

              <Form.Group className="mb-0">
                <label className="text-muted mb-0">Sector</label>
                <p>{this.state.company._sector_?.sector}</p>
              </Form.Group>
            </Col>
            <Col md={4} className="pr-0">
              <label className="text-muted mb-0">Imagen</label> <br />
              <img
                src={this.state.company.foto}
                width="318"
                height="200"
                alt="product"
              />
            </Col>
          </Row>
        </div>
      );
    } else {
      content = (
        <div>
          <Row className="mb-4">
            <Col md={1} className="pr-0">
              <div className="img-container">
                <img
                  onClick={() => this.ShowFileDialog("brand-input")}
                  src={this.state.company.logo}
                  width="80"
                  height="80"
                  alt="brandlogo"
                  className="img-blur"
                />
              </div>
              <input
                id="brand-input"
                onChange={this.UploadImage}
                type="file"
                name="name"
                style={{ display: "none" }}
              />
            </Col>
            <Col md={7}>
              <h3 className="mb-0 mt-2">{this.state.company.nombre}</h3>
              {this.state.company.slogan}
            </Col>
            <Col md={3} className="text-right pt-3">
              <Button
                onClick={() => this.setState({ editProfile: false })}
                className="mx-2 btn-lightblue"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  this.saveprofile(frm);
                }}
                className="btn-lightblue"
              >
                Guardar
              </Button>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="pl-0">
              <Form.Group className="mb-0 px-3">
                <label className="text-muted mb-0">Descripción</label>
                <Form.Control
                  defaultValue={this.state.company.descripcion}
                  onChange={(e) => (frm.descripcion = e.target.value)}
                  className="input-black"
                />
              </Form.Group>

              <Form.Group className="mb-0 px-3">
                <label className="text-muted mb-0">Teléfono</label>
                <InputMask
                  className="input-black form-control"
                  mask="(999) 999-9999"
                  onChange={(e) => (frm.telefono = e.target.value)}
                  defaultValue={this.state.company.telefono}
                />
              </Form.Group>

              <Form.Group className="mb-0 px-3">
                <label className="text-muted mb-0">WhatsApp</label>
                <InputMask
                  className="input-black form-control"
                  mask="(999) 999-9999"
                  onChange={(e) => (frm.whatsapp = e.target.value)}
                  defaultValue={this.state.company.whatsapp}
                />
              </Form.Group>
              <Form.Group className="mb-0 px-3">
                <label className="text-muted mb-0">Contacto</label>
                <Form.Control
                  as="select"
                  defaultValue={this.state.company._contacto_?._id}
                  onChange={(e) => (frm.contacto = e.target.value)}
                  className="input-black"
                >
                  <option disabled value="NA">
                    Seleccionar Contacto
                  </option>
                  {this.state.company._empleados_?.map((val, indx) => (
                    <option key={indx} value={val._id}>
                      {val.nombre}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-0">
                <label className="text-muted mb-0">direccion</label>
                <Form.Control
                  defaultValue={this.state.company.direccion}
                  onChange={(e) => (frm.direccion = e.target.value)}
                  className="input-black"
                />
              </Form.Group>

              <Form.Group className="mb-0">
                <label className="text-muted mb-0">Provincia</label>
                <Form.Control
                  value={this.state.provincia}
                  as="select"
                  onChange={(e) => this.SelectProvince(e.target.value)}
                  className="input-black"
                >
                  <option disabled value="0">
                    Seleccionar Provincia
                  </option>
                  {this.state.provincias?.map((val, indx) => (
                    <option key={indx} value={val.provincia_id}>
                      {val.provincia}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-0">
                <label className="text-muted mb-0">Municipio</label>
                <Form.Control
                  as="select"
                  value={this.state.municipio}
                  onChange={(e) => this.SelectMunicipe(e.target.value)}
                  className="input-black"
                >
                  <option disabled value="0">
                    Seleccionar Municipio
                  </option>
                  {this.state.municipios?.map((val, indx) => (
                    <option key={indx} value={val.minicipio_id}>
                      {val.minicipio}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-0">
                <label className="text-muted mb-0">Sector</label>
                <Form.Control
                  as="select"
                  value={this.state.sector}
                  onChange={(e) => this.SelectSector(e.target.value)}
                  className="input-black"
                >
                  <option disabled value="0">
                    Seleccionar Sector
                  </option>
                  {this.state.sectores?.map((val, indx) => (
                    <option key={indx} value={val.sector_id}>
                      {val.sector}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={3} className="pr-0">
              <label className="text-muted mb-0">Imagen</label> <br />
              <div className="img-container-lg">
                <img
                  onClick={() => this.ShowFileDialog("profile-input")}
                  src={this.state.company.foto}
                  width="318"
                  height="200"
                  alt="product"
                  className="img-blur"
                />
              </div>
              <div className="mt-n5 mr-2 text-right">
                <input
                  id="profile-input"
                  onChange={this.UploadImage}
                  type="file"
                  name="name"
                  style={{ display: "none" }}
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }
    return content;
  }

  ProfileSave(frm) {
    let params = {
      _id: frm._id,
      nombre: frm.nombre,
      descripcion: frm.descripcion,
      direccion: frm.direccion,
      provincia: this.state.provincia,
      municipio: this.state.municipio,
      sector: this.state.sector,
      logo: this.state.brandLogo === "" ? frm.logo : this.state.brandLogo,
      foto: this.state.profileLogo === "" ? frm.foto : this.state.profileLogo,
      slogan: frm.slogan,
      telefono: frm.telefono,
      whatsapp: frm.whatsapp,
      contacto: frm.contacto,
      estado: frm.estado,
      fecha_modificacion: frm.fecha_modificacion,
    };

    console.log("ProfileSave:", {params});

    UpdateCompany(params).then((resp) => {
      Swal.fire("Información", resp.data.message, "success");
      GetCompanyById(this.props.empresa._id).then((resp) => {
        this.setState({
          company: resp.data.result,
          editProfile: !this.state.editProfile,
        });
      });
    });
  }

  async enableEdit() {
    GetProvinces().then((resp) =>
      this.setState({ provincias: resp.data.message, editProfile: true })
    );
    await this.SelectProvince(this.state.company.provincia);
    await this.SelectMunicipe(this.state.company.municipio);
    this.setState({ sector: this.state.company._sector_?.sector_id || 0 });
    this.getCompany();
  }

  ShowFileDialog(id) {
    $(`#${id}`).trigger("click");
  }

  FileUpload = (event) => {
    let url = `${process.env.REACT_APP_CLOUDINARYURL}${process.env.REACT_APP_CLOUDNAME}/upload`;
    const formData = new FormData();

    for (let i = 0; i < event.target.files.length; i++) {
      let file = event.target.files[i];
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_UPLOADPRESET);

      axios
        .post(url, formData)
        .then((resp) => {
          if (event.target.id === "brand-input") {
            this.setState({
              brandLogo: resp.data.secure_url,
            });
          } else {
            this.setState({
              profileLogo: resp.data.secure_url,
            });
          }
        })
        .catch((errm) => {
          Swal.fire("Error", "Error al intentar cargar la imagen", "error");
        });
    }
  };

  UploadImage = (event) => {
    console.log("UploadImage:", event);

    let file = event.target.files[0];

    console.log("UploadImage:", file);

    if (!file) {
      console.log("No hay un archivo seleccionado.");
      return "";
    }

    const fileName = (uuidv4() + "_" + file.name)
      .toLowerCase()
      .replaceAll(" ", "_")
      .replaceAll("-", "_");

    console.log("UploadImage:", fileName);

    const blobServiceClient = BlobServiceClient.fromConnectionString(
      process.env.REACT_APP_AZURE_STORAGE_CONNECTION_STRING
    );

    const containerClient = blobServiceClient.getContainerClient(
      process.env.REACT_APP_AZURE_CONTAINER_NAME
    );

    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

    blockBlobClient.uploadData(file).then(
      () => {
        const url = `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net/${process.env.REACT_APP_AZURE_CONTAINER_NAME}/${fileName}`;

        console.log("UploadImage:", {url});

        console.log("UploadImage:", event.target.id);

        if (event.target.id === "brand-input") {
          this.setState({
            brandLogo: url
          });
        } else {
          this.setState({
            profileLogo: url
          });
        }
      },
      (error) => {
        console.error("Error en la carga de archivos a Azure:", error);
        Swal.fire("Error", "Error al intentar cargar la imagen", "error");
      }
    );
  };

  async SelectProvince(value) {
    await GetMunicipies(value).then((resp) =>
      this.setState({ municipios: resp.data.message })
    );
    this.setState({
      provincia: value,
      municipio: "0",
      sector: "0",
    });
  }

  async SelectMunicipe(value) {
    await GetSectors(value).then((resp) =>
      this.setState({ sectores: resp.data.message })
    );
    this.setState({
      municipio: value,
      sector: "0",
    });
  }

  SelectSector(value) {
    this.setState({ sector: value });
  }

  async Remove(item) {
    item.estado = "borrado";
    switch (this.state.table) {
      case table.OFFERTS:
        await UpdateOffert(item).then((resp) => {
          Swal.fire("Información", resp.data.message, "success");
        });
        break;

      case table.OFFICES:
        await UpdateOffice(item).then((resp) => {
          Swal.fire("Información", resp.data.message, "success");
        });
        break;

      case table.RESPONSABLE:
        await UpdateEmployee(item).then((resp) => {
          Swal.fire("Información", resp.data.message, "success");
        });
        break;

      case table.CATALOG:
        item.estado = "inactivo";
        await UpdateCatalog(item).then((resp) => {
          Swal.fire("Información", resp.data.message, "success");
        });
        break;

      case table.CATEGORY:
        item.estado = "inactivo";
        await UpdateCategory(item).then((resp) => {
          Swal.fire("Información", resp.data.message, "success");
        });
        break;

      default:
        break;
    }
    this.toggleTab(this.state.table);
  }

  Selected(item) {
    if (this.state.forms === Forms.OFFERTS) {
      this.props.history.push(`/orderdetail/${item._id}`);
    } else {
      if (this.state.forms === Forms.CATALOGDETAIL)
        this.setState({ forms: Forms.CATALOG });

      this.setState({ modelData: item });
      this.showDialog();
    }
  }

  View(item) {
    this.setState({
      forms: Forms.CATALOGDETAIL,
      modelData: item,
      isView: true,
    });
    this.showDialog();
  }

  ToggleModal = () => {
    if (this.state.isOpen === true)
      this.setState({
        isEdit: false,
        isView: false,
        modelData: {},
        forms: this.state.isView ? Forms.CATALOG : this.state.forms,
      });
    this.setState({ isOpen: !this.state.isOpen });
  };

  async SaveChange(state) {
    switch (this.state.table) {
      case table.OFFERTS:
        let paramsOffert = {
          nombre: state.nombre,
          descripcion: state.descripcion,
          palabras_claves: state.palabras_claves,
          foto: state.foto,
          precio: state.precio,
          multiple: state.multiple,
          cantidad: state.cantidad,
          proveedor: state.proveedor,
          sucursales: state.sucursales,
          responsable: state.responsable,
          cantidad_ofertas_minimo: state.cantidad_ofertas_minimo,
          cantidad_dias_minimos: state.cantidad_dias_minimos,
          tipo: state.tipo,
          fecha_inicial: state.fecha_inicial,
          fecha_final: state.fecha_final,
          estado: state.estado,
        };

        await RegisterOffert(paramsOffert).then((resp) => {
          Swal.fire("Información", resp.data.message, "success");
        });
        this.toggleTab(table.OFFERTS);
        break;

      case table.OFFICES:
        let paramsOffice = {
          nombre: state.nombre,
          direccion: state.direccion,
          telefono: state.telefono,
          provincia: state.provincia,
          municipio: state.municipio,
          sector: state.sector,
          latitud: state.latitud,
          longitud: state.longitud,
          contacto: state.contacto,
          empresa: state.empresa,
          estado: "activo",
        };

        if (!state.isEdit) {
          await RegisterOffice(paramsOffice).then((resp) => {
            Swal.fire("Información", resp.data.message, "success");
          });
        } else {
          paramsOffice._id = state._id;
          await UpdateOffice(paramsOffice).then((resp) => {
            Swal.fire("Información", resp.data.message, "success");
          });
        }
        this.toggleTab(table.OFFICES);
        break;

      case table.RESPONSABLE:
        let paramsResponsable = {
          nombre: state.nombre,
          cedula: state.cedula,
          cargo: state.cargo,
          telefono: state.telefono,
          whatsapp: state.whatsapp,
          email: state.email,
          usuario: state.usuario,
          contrasena: state.contrasena,
          empresa: state.empresa,
          estado: "activo",
          puede_admin_ofertas: false,
          puede_admin_empleados: false,
          puede_admin_sucursales: false,
          super_usuario: false,
        };

        if (!state.isEdit) {
          await RegisterEmployee(paramsResponsable).then((resp) => {
            Swal.fire("Información", resp.data.message, "success");
          });
        } else {
          paramsResponsable._id = state._id;
          await UpdateEmployee(paramsResponsable).then((resp) => {
            Swal.fire("Información", resp.data.message, "success");
          });
        }
        this.toggleTab(table.RESPONSABLE);
        break;

      case table.CATALOG:
        let paramsCatalog = {
          nombre: state.nombre,
          descripcion: state.descripcion,
          palabras_claves: state.palabras_claves,
          foto: state.foto,
          precio: state.precio,
          precio_oferta: state.precio_oferta,
          categoria: state.categoria,
          proveedor: state.provider,
          tipo: state.tipo,
          oferta: state.oferta,
          adicionales: state.adicionales,
          estado: state.estado,
        };

        if (!state.isEdit) {
          await RegisterCatalog(paramsCatalog)
            .then((resp) => {
              if (resp.data.success) {
                Swal.fire("Información", resp.data.message, "success");
              } else {
                Swal.fire("Error", resp.data.message, "error");
              }
            })
            .catch((err) => {
              Swal.fire("Error", err.error.message, "error");
            });
        } else {
          paramsCatalog._id = state._id;
          await UpdateCatalog(paramsCatalog).then((resp) => {
            Swal.fire("Información", resp.data.message, "success");
          });
        }
        this.toggleTab(table.CATALOG);
        break;

      case table.CATEGORY:
        let paramsCategory = {
          nombre: state.nombre,
          descripcion: state.descripcion,
          estado: state.estado, //"activo"
        };

        if (!state.isEdit) {
          await RegisterCategory(paramsCategory).then((resp) => {
            Swal.fire("Información", resp.data.message, "success");
          });
        } else {
          paramsCategory._id = state._id;
          await UpdateCategory(paramsCategory).then((resp) => {
            Swal.fire("Información", resp.data.message, "success");
          });
        }
        this.toggleTab(table.CATEGORY);
        break;

      default:
        break;
    }

    this.setState({
      isEdit: false,
      modelData: {},
    });

    this.showDialog();
  }

  async toggleTab(selectedTable) {
    let items = [];
    let cols = [];
    let label = "";
    let labelEdit = "";
    let searchText = "";
    let labelDetail = "";
    let frm = null;

    switch (selectedTable) {
      case table.OFFERTS:
        cols = [
          "Título",
          "Descripción",
          "Imagen",
          "Fecha",
          "Duración",
          "Acciones",
        ];
        await GetAllOffertById(this.props.provider).then((resp) => {
          let filter = resp.data.result.sort(
            (a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion)
          );
          items = filter;
          hasAccessCatalog = items.some((item) => {
            return item.tipo === "catalogo";
          });
        });
        label = "Crear Ofertas";
        labelEdit = "Editar Ofertas";
        frm = Forms.OFFERTS;
        break;

      case table.OFFICES:
        cols = ["Nombre", "Dirección", "Teléfono", "Provincia", "Acciones"];
        await GetAllOfficeById(this.props.empresa._id).then((resp) => {
          let filter = resp.data.result.sort(
            (a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion)
          );
          items = filter;
        });
        label = "Crear Sucursal";
        labelEdit = "Editar Sucursal";
        frm = Forms.OFFICES;
        break;

      case table.RESPONSABLE:
        cols = ["Responsable", "Sucursal", "Teléfono", "Cédula", "Acciones"];

        await GetEmployeeByCompanyId(this.props.empresa._id).then((resp) => {
          let filter = resp.data.result.sort(
            (a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion)
          );
          items = filter;
        });

        items.map(
          (val) => (val.sucursal = this.state.company._sector_?.sector)
        );
        label = "Crear Responsable";
        labelEdit = "Editar Responsable";
        frm = Forms.RESPONSABLE;
        break;

      case table.CATALOG:
        cols = [
          "Producto",
          "Descripción",
          "Imagen",
          "Fecha creación",
          "Precio de oferta",
          "Precio regular",
          "Estado",
          "Acciones",
        ];

        await GetAllCatalog(this.props.provider).then((resp) => {
          let filter = resp.data.result.sort(
            (a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion)
          );
          items = filter;
        });
        // items.map((val) => (val.sucursal = this.state.company._sector_?.sector));
        // items = [{nombre : 'Lunch menu', descripcion:'15% de descuento en lunch menu', foto:'https://bocao.com.do/uploads/img16-12-2014-271135986.jpg', precio:500.98}]
        label = "Crear Producto";
        labelEdit = "Editar Producto";
        searchText = "Buscar productos";
        labelDetail = "Detalle del producto";
        frm = Forms.CATALOG;
        break;

      case table.CATEGORY:
        cols = ["Nombre", "Descripción", "Estado", "Acciones"];

        await GetAllCategory(this.props.provider).then((resp) => {
          let filter = resp.data.result.sort(
            (a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion)
          );
          items = filter;
        });
        // items.map((val) => (val.sucursal = this.state.company._sector_?.sector));
        // items = [{nombre : 'Lunch menu', descripcion:'15% de descuento en lunch menu', foto:'https://bocao.com.do/uploads/img16-12-2014-271135986.jpg', precio:500.98}]
        label = "Crear Categoría";
        labelEdit = "Editar Categoría";
        searchText = "Buscar categorías";
        frm = Forms.CATEGORY;
        break;

      default:
        break;
    }

    this.setState({
      data: items,
      dataFilter: items,
      columns: cols,
      table: selectedTable,
      btnLabel: label,
      btnLabelEdit: labelEdit,
      inputSearchLabel: searchText,
      labelDetail: labelDetail,
      hasAccessCatalog: hasAccessCatalog,
      forms: frm,
    });
  }

  async getCompany() {
    await GetCompanyById(this.props?.empresa._id).then((resp) => {
      this.setState({
        company: resp.data.result,
      });
    });
  }

  HandleSearch = (value) => {
    const data = this.state.dataFilter.filter(
      (item) =>
        item.nombre.toLowerCase().includes(value.toLowerCase()) ||
        item.descripcion.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({
      data,
    });
  };

  async componentDidMount() {
    await this.getCompany();
    this.toggleTab(table.OFFERTS);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="py-4 mx-auto w-75">
          {this.ProfileContent()}

          <div className="my-4">
            <Row>
              <Col>
                <Card
                  title={"Ofertas creadas"}
                  content={this.props.ofertascreadas}
                />
              </Col>
              <Col>
                <Card
                  title={"Ofertas activas"}
                  content={this.props.ofertasactivas}
                />
              </Col>
              <Col>
                <Card
                  title={"Ofertas canjeadas"}
                  content={this.props.ofertascanjeadas}
                />
              </Col>
              <Col>
                <Card
                  title={"Ofertas por vencer"}
                  content={this.props.ofertasavencer}
                />
              </Col>
              <Col>
                <Card
                  title={"Equivalente en pesos"}
                  content={`${CurrencyFormat(
                    this.props.ofertasmontocanjeadas.toString()
                  )}`}
                />
              </Col>
            </Row>
          </div>

          <div className="rounded-sm">
            <div className="d-flex justify-content-between">
              <div>
                <Nav
                  className="tab-bg"
                  variant="tabs"
                  defaultActiveKey="Offerts"
                >
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        this.changeTab(table.OFFERTS);
                      }}
                      eventKey="Offerts"
                    >
                      <strong>Ofertas</strong>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        this.changeTab(table.OFFICES);
                      }}
                      eventKey="Offices"
                      className="rounded-0"
                    >
                      <strong>Sucursales</strong>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        this.changeTab(table.RESPONSABLE);
                      }}
                      eventKey="Responsable"
                    >
                      <strong>Responsable</strong>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item hidden={!this.state.hasAccessCatalog}>
                    <Nav.Link
                      onClick={() => {
                        this.changeTab(table.CATALOG);
                      }}
                      eventKey="Catálogo"
                    >
                      <strong>Catálogo</strong>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item hidden={!this.state.hasAccessCatalog}>
                    <Nav.Link
                      onClick={() => {
                        this.changeTab(table.CATEGORY);
                      }}
                      eventKey="Categorías"
                    >
                      <strong>Categorías</strong>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <>
                  {this.state.inputSearchLabel !== "" && (
                    <InputGroup
                      onChange={(e) => this.HandleSearch(e.target.value)}
                      size="5"
                      style={{ width: "60%" }}
                    >
                      <FormControl
                        className="input-black"
                        type="text"
                        placeholder={this.state.inputSearchLabel}
                        style={{ border: "0.1px solid #506183" }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          marginLeft: "80%",
                          color: "#3BC1D3",
                          marginTop: "8px",
                        }}
                        className="search-icon"
                      >
                        <i class="fas fa-search" />
                      </div>
                    </InputGroup>
                  )}
                </>

                <Button
                  onClick={() => this.showDialog()}
                  className="btn-lightblue"
                >
                  {this.state.btnLabel}
                </Button>
              </div>
            </div>
            <CustomTable
              perPage={5}
              delete={this.delete}
              view={this.view}
              edit={this.select}
              items={this.state.data}
              columns={this.state.columns}
              tableType={this.state.table}
            />
          </div>

          <ModalDialog
            editData={this.state.modelData}
            Forms={this.state.isView ? Forms.CATALOGDETAIL : this.state.forms}
            SaveChange={this.save}
            title={
              this.state.isView
                ? this.state.labelDetail
                : !this.state.modelData._id
                ? this.state.btnLabel
                : this.state.btnLabelEdit
            }
            openModal={this.state.isOpen}
            onClose={this.showDialog}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStatetoProp)(withRouter(Profile));
