import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { connect } from "react-redux";

function mapStatetoProp(state) {
  return state;
}

class NavbarTool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      intervalFn: null,
    };

    this.isAuthorizedUser = this.props.super_usuario || this.props.IsAgency;
  }

  componentDidMount() {
    let interval = setInterval(() => {
      this.setState({
        date: new Date(),
      });
    }, 1000);

    this.setState({
      intervalFn: interval,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalFn);
  }

  render() {
    return (
      <Navbar className="bg-black justify-content-center">
        <Navbar.Brand>
          <img
            src="/assets/logo.png"
            width="150"
            height="50"
            className="d-inline-block align-top ml-4"
            alt="logo"
            onClick={() =>
              this.isAuthorizedUser
                ? this.props.history.push(`/dashboard`)
                : null
            }
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="mx-auto d-none d-md-block">
            {this.isAuthorizedUser ? (
              <h4 className="m-0">
                <Link className="text-white" to="/profile">
                  {this.props.empresa?.nombre}
                </Link>
              </h4>
            ) : (
              <h4 className="text-white m-0">{this.props.empresa?.nombre}</h4>
            )}
          </Navbar.Text>
          <Navbar.Text className="text-white mx-2 d-none d-md-block">
            {this.state.date.toLocaleTimeString()}
          </Navbar.Text>
          <Navbar.Text>
            <Link className="text-lightblue" to="/login">
              <span className="d-none d-md-inline">Cerrar sesión</span>
              <i className="fas fa-sign-out-alt mx-2 text-lightblue"></i>
            </Link>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default connect(mapStatetoProp)(withRouter(NavbarTool));
