import React from "react";
import { Button, Row, Col, Badge } from "react-bootstrap";
// import { CurrencyFormat } from "../../utils/currency.util";
// import { FormatDate } from "../../utils/date.util";

class CatalogDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReadonly: true,
    };
  }
  render() {
    console.log(this.props);
    return (
      <>
        <div>
          <Row className="mb-4 p-2">
            <Col md={6}>
              <label className="text-muted mb-0 pt-4">Nombre</label>
              <p>{this.props.data.nombre}</p>

              <label className="text-muted mb-0">Descripción</label>
              <p>{this.props.data.descripcion}</p>

              <label className="text-muted mb-0">Precio regular</label>
              <p>RD${this.props.data.precio}</p>

              <label className="text-muted mb-0">Precio de oferta</label>
              <p>RD${this.props.data.precio_oferta}</p>

              <label className="text-muted mb-0">Categoría</label>
              <p>{this.props?.data?._categorias_?.nombre}</p>

              <label className="text-muted mb-0 ">Tipo</label>
              <p>{this.props?.data?.tipo}</p>          
            </Col>

            <Col md={6}>
              <div>
                <label className="text-muted mb-0 pt-4 pb-3">Imagen</label>
                <img
                  src={this.props.data.foto}
                  width="332"
                  height="192"
                  alt="product"
                />
              </div>


              <label className="text-muted mb-0 pt-4">Palabras claves</label>
              <p>{this.props.data.palabras_claves}</p>

              {this.props?.data?.tipo === 'combo' && <>
                <label className="text-muted mb-0">Guarniciones</label>
                <p> {
                  this.props?.data?.adicionales?.filter((adicional) => adicional.tipo === 'guarnicion').map((val, indx) =>
                    <Badge key={indx} variant="secondary" className="p-2 my-2 mx-1 rounded-pill">{val.nombre}</Badge>
                  )
                }</p>

                <label className="text-muted mb-0">Bebidas</label>
                <p> {
                  this.props?.data?.adicionales?.filter((adicional) => adicional.tipo === 'bebida').map((val, indx) =>
                    <Badge key={indx} variant="secondary" className="p-2 my-2 mx-1 rounded-pill">{val.nombre}</Badge>
                  )
                }</p>
              </>}



            </Col>
          </Row>
        </div>

        <div className="text-center m-4">
          <Button className="mx-1 btn-cancel" onClick={this.props.close}>
            Cerrar
          </Button>
        </div>
      </>
    );
  }
}

export default CatalogDetail;
