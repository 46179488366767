import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  GetProvinces,
  GetMunicipies,
  GetSectors,
} from "../../services/location.services";
import { GetEmployeeByCompanyId } from "../../services/employee.services";
import Map from "../map/map";
import InputMask from "react-input-mask";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return state;
}

class RegisterOffice extends React.Component {
  constructor(props) {
    super(props);

    let editData = this.props.editData;

    this.state = {
      isEdit: editData.nombre === undefined ? false : true,
      nombre: editData.nombre === undefined ? "" : editData.nombre,
      direccion: editData.direccion === undefined ? "" : editData.direccion,
      telefono: editData.telefono === undefined ? "" : editData.telefono,
      provincia: editData.provincia === undefined ? "" : editData.provincia,
      municipio: editData.municipio === undefined ? "" : editData.municipio,
      sector: editData.sector === undefined ? "" : editData.sector,
      latitud: editData.latitud === undefined ? "" : editData.latitud,
      longitud: editData.longitud === undefined ? "" : editData.longitud,
      contacto: editData.contacto === undefined ? "" : editData.contacto,
      empresa: this.props.empresa._id,
      estado: editData.estado === undefined ? "activo" : editData.estado,
      provincias: [],
      municipios: [],
      sectores: [],
      company: [],
      isInvalid: false,
    };

    this.latlngSelect = this.latlng.bind(this);

    if (editData._id) {
      this.state._id = editData._id;
      this.SelectProvince(editData.provincia);
      this.SelectMunicipe(editData.municipio);
      this.SelectSector(editData.sector);
    }
  }

  Commit = (event) => {
    const form = document.getElementById("frm");
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        isInvalid: true,
      });
      return;
    }

    // if (this.state.latitud === "") {
    //     Swal.fire("Información", "Debe seleccionar una ubicacion antes de continuar", "warning");
    //     return;
    // }

    this.props.commit(this.state);
  };

  latlng = (values) => {
    this.setState({
      latitud: values.lat,
      longitud: values.lng,
    });
  };

  SelectProvince(value) {
    if (!value) return;
    GetMunicipies(value).then((resp) =>
      this.setState({ municipios: resp.data.message })
    );
    this.setState({ provincia: value, municipio: "", sector: "" });
  }

  SelectMunicipe(value) {
    if (!value) return;
    GetSectors(value).then((resp) =>
      this.setState({ sectores: resp.data.message })
    );
    this.setState({ municipio: value, sector: "" });
  }

  SelectSector(value) {
    if (!value) return;
    this.setState({ sector: value });
  }

  componentDidMount() {
    GetProvinces().then((resp) =>
      this.setState({ provincias: resp.data.message })
    );
    GetEmployeeByCompanyId(this.props.empresa._id).then((resp) =>
      this.setState({ company: resp.data.result })
    );
  }

  render() {
    return (
      <Form id="frm" noValidate validated={this.state.isInvalid}>
        <Row>
          <Col md={6}>
            <Form.Group>
              <label>Nombre</label>
              <Form.Control
                required
                defaultValue={this.state.nombre}
                onChange={(e) => this.setState({ nombre: e.target.value })}
                className="input-black"
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <label>Teléfono</label>
              <InputMask
                required
                className="input-black form-control"
                mask="(999) 999-9999"
                onChange={(e) => this.setState({ telefono: e.target.value })}
                value={this.state.telefono}
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group>
              <label>Dirección</label>
              <textarea
                required
                defaultValue={this.state.direccion}
                onChange={(e) => this.setState({ direccion: e.target.value })}
                className="input-black form-control"
                rows="4"
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group>
              <label>Provincia</label>
              <Form.Control
                required
                value={this.state.provincia}
                as="select"
                onChange={(e) => {
                  this.SelectProvince(e.target.value);
                }}
                className="input-black"
              >
                <option disabled value="">
                  Seleccionar Provincia
                </option>
                {this.state.provincias?.map((val, indx) => (
                  <option key={indx} value={val.provincia_id}>
                    {val.provincia}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <label>Municipio</label>
              <Form.Control
                required
                value={this.state.municipio}
                as="select"
                onChange={(e) => {
                  this.SelectMunicipe(e.target.value);
                }}
                className="input-black"
              >
                <option disabled value="">
                  Seleccionar Municipio
                </option>
                {this.state.municipios?.map((val, indx) => (
                  <option key={indx} value={val.minicipio_id}>
                    {val.minicipio}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form>
              <Form.Group>
                <label>Sector</label>
                <Form.Control
                  required
                  value={this.state.sector}
                  as="select"
                  onChange={(e) => this.SelectSector(e.target.value)}
                  className="input-black"
                >
                  <option disabled value="">
                    Seleccionar Sector
                  </option>
                  {this.state.sectores?.map((val, indx) => (
                    <option key={indx} value={val.sector_id}>
                      {val.sector}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Col>
          <Col md={6}>
            <Form.Group>
              <label>Contacto</label>
              <Form.Control
                required
                value={this.state.contacto}
                onChange={(e) => this.setState({ contacto: e.target.value })}
                className="input-black"
                as="select"
              >
                <option disabled value="">
                  Seleccionar Contacto
                </option>
                {this.state.company?.map((val, indx) => (
                  <option key={indx} value={val._id}>
                    {val.nombre}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div style={{ height: "400px", width: "100%" }}>
          <Map
            latlng={this.latlngSelect}
            defaultValue={{
              latitud: this.state.latitud,
              longitud: this.state.longitud,
            }}
          />
        </div>

        <div className="text-center py-2">
          <Button className="mx-1 btn-cancel" onClick={this.props.close}>
            Cancelar
          </Button>
          <Button className="mx-1 btn-lightblue" onClick={this.Commit}>
            Guardar
          </Button>
        </div>
      </Form>
    );
  }
}
export default connect(mapStateToProps)(RegisterOffice);
