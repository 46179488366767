const Forms = {
  OFFERTS: 1,
  OFFICES: 2,
  RESPONSABLE: 3,
  OFFICESDETAIL: 4,
  CANJE: 5,
  CATALOG: 6,
  CATEGORY: 7,
  CATALOGDETAIL: 8,
  INFO: 9,
};

export default Forms;
