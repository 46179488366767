import React from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => (
  <div>
    <i className="fas fa-3x fa-map-marker-alt text-danger"></i>
  </div>
);

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableGeo: false,
      center: { lat: 0, lng: 0 },
      zoom: 15,
      selectedLocation: {
        lat: this.props.defaultValue.latitud
          ? this.props.defaultValue.latitud
          : null,
        lng: this.props.defaultValue.longitud
          ? this.props.defaultValue.longitud
          : null,
      },
    };
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((result) => {
        this.setState({
          enableGeo: true,
          center: {
            lat: result.coords.latitude,
            lng: result.coords.longitude,
          },
        });
      });
    }
  }

  handleClick = ({ x, y, lat, lng, event }) => {
    this.setState({
      selectedLocation: {
        lat: lat,
        lng: lng,
      },
    });
    this.props.latlng({ lat, lng });
  };

  renderMarket() {
    if (this.state.selectedLocation.lat) {
      return (
        <AnyReactComponent
          lat={this.state.selectedLocation.lat}
          lng={this.state.selectedLocation.lng}
          text="Sucursal"
        />
      );
    }
  }

  render() {
    return (
      <>
        {this.state.enableGeo ? (
          <GoogleMapReact
            options={{ gestureHandling: "greedy", clickableIcons: true }}
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAPSECRET }}
            defaultCenter={this.state.center}
            defaultZoom={this.state.zoom}
            onClick={this.handleClick}
          >
            {this.renderMarket()}
          </GoogleMapReact>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default Map;
