import React from "react";
import { Chart } from "chart.js";

export default class CustomCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartBuilder: null,
      labels: [],
      data: [],
      maxstep: 0,
    };
  }

  getLastItem(data) {
    let items = [...data];
    let max = items.sort();
    return max[items.length - 1];
  }

  componentDidUpdate() {
    if (this.state.labels !== this.props.labels) {
      if (this.props.chartType === "line") {
        Chart.defaults.line.spanGaps = true;
      }

      let maxNumber = this.getLastItem(this.props.data);
      this.setState({ maxstep: maxNumber });

      var chart = new Chart(`chartcontent${this.props.chartType}`, {
        type: this.props.chartType,
        data: {
          labels: this.props.labels,
          datasets: [
            {
              tension: 0,
              pointBackgroundColor: "#ffffff",
              fill: false,
              data: this.props.data,
              backgroundColor: "#3BC1D3",
              borderColor: ["#3BC1D3"],
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontColor: "#FFFFFF",
                },
              },
            ],
            yAxes: [
              {
                position: "right",
                gridLines: {
                  color: "#404457",
                  drawBorder: false,
                },
                ticks: {
                  fontColor: "#FFFFFF",
                  suggestedMin0: 0,
                  suggestedMax: this.state.maxstep,
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });

      this.setState({
        chartBuilder: chart,
        labels: this.props.labels,
        data: this.props.data,
      });
    }
  }

  render() {
    return (
      <div className="rounded-sm bg-card px-2 py-2 customchart">
        <h5 className="">Cantidad de ofertas canjeadas (Meses)</h5>
        <canvas height="50" id={`chartcontent${this.props.chartType}`}>
          {this.chartBuilder}
        </canvas>
      </div>
    );
  }
}
