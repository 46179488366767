import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return state;
}

class RegisterResponsable extends React.Component {
  constructor(props) {
    super(props);
    let editData = this.props.editData;

    this.state = {
      isEdit: editData.nombre === undefined ? false : true,
      nombre: editData === {} ? "" : editData.nombre,
      cargo: editData === {} ? "" : editData.cargo,
      telefono: editData === {} ? "" : editData.telefono,
      whatsapp: editData === {} ? "" : editData.whatsapp,
      email: editData === {} ? "" : editData.email,
      cedula: editData === {} ? "" : editData.cedula,
      empresa: this.props.empresa._id,
      estado: editData === {} ? "activo" : editData.estado,
      isInvalid: false,
    };

    if (editData) {
      this.state._id = editData._id;
    }

    this.commit = this.Commit.bind(this);
  }

  Commit = (event) => {
    const form = document.getElementById("frm");
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        isInvalid: true,
      });
      return;
    }
    this.props.commit({ ...this.state });
  };

  render() {
    return (
      <Form id="frm" noValidate validated={this.state.isInvalid}>
        <Row>
          <Col md={6}>
            <Form.Group>
              <label>Nombre</label>
              <Form.Control
                required
                defaultValue={this.state.nombre}
                onChange={(e) => this.setState({ nombre: e.target.value })}
                className="input-black"
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <label>Cargo</label>
              <Form.Control
                required
                defaultValue={this.state.cargo}
                onChange={(e) => this.setState({ cargo: e.target.value })}
                className="input-black"
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group>
              <label>Whatsapp</label>
              <InputMask
                required
                className="input-black form-control"
                mask="(999) 999-9999"
                onChange={(e) => this.setState({ whatsapp: e.target.value })}
                value={this.state.whatsapp}
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <label>Email</label>
              <Form.Control
                required
                defaultValue={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                className="input-black"
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group>
              <label>Teléfono</label>
              <InputMask
                required
                className="input-black form-control"
                mask="(999) 999-9999"
                onChange={(e) => this.setState({ telefono: e.target.value })}
                value={this.state.telefono}
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <label>Cédula</label> <br />
              <InputMask
                required
                className="input-black form-control"
                mask="999-9999999-9"
                onChange={(e) => this.setState({ cedula: e.target.value })}
                value={this.state.cedula}
              />
              <Form.Control.Feedback type="invalid">
                Campo requerido
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="text-center py-2">
          <Button className="mx-1 btn-cancel" onClick={this.props.close}>
            Cancelar
          </Button>
          <Button className="mx-1 btn-lightblue" onClick={this.commit}>
            Guardar
          </Button>
        </div>
      </Form>
    );
  }
}

export default connect(mapStateToProps)(RegisterResponsable);
