import React from "react";
import { Badge, Button } from "react-bootstrap";

class OfficeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.items,
    });
  }

  componentDidUpdate(oldValues, newValues) {
    if (this.props.items?.length === 0) return;

    if (this.state.data !== this.props.items) {
      this.setState({
        data: this.props.items,
      });
    }
  }

  render() {
    return (
      <div className="px-2">
        {this.state.data?.map((val, indx) => (
          <Badge
            key={indx}
            variant="secondary"
            className="p-2 my-2 mx-1 rounded-pill"
          >
            {val.nombre}
          </Badge>
        ))}
        <div className="text-center mt-3">
          <Button className="mx-1 btn-cancel" onClick={this.props.close}>
            Cerrar
          </Button>
        </div>
      </div>
    );
  }
}

export default OfficeDetail;
