import { axiosServices } from "./config.services";

export async function RegisterOffice(model) {
  return await axiosServices.post("/sucursales/create", model);
}

export async function UpdateOffice(model) {
  return await axiosServices.post("/sucursales/update", model);
}

export async function GetAllOffice() {
  return await axiosServices.get("/sucursales/listpop");
}

export async function GetAllOfficeById(Id) {
  return await axiosServices.get(`/sucursales/listasucursalesporempresa/${Id}`);
}

export async function GetOfficeById(id) {
  return await axiosServices.get(`/sucursales/find/${id}`);
}
