import { axiosServices } from "./config.services";

export async function RegisterEmployee(model) {
  return await axiosServices.post("/empleados/create", model);
}

export async function UpdateEmployee(model) {
  return await axiosServices.post("/empleados/update", model);
}

export async function GetAllEmployee() {
  return await axiosServices.get("/empleados/listpop");
}

export async function GetEmployeeByCompanyId(id) {
  return await axiosServices.get(`/empleados/listaempleadosporempresa/${id}`);
}

export async function GetEmployeeById(id) {
  return await axiosServices.get(`/empleados/find/${id}`);
}
