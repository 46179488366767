import axios from "axios";

export async function GetProvinces() {
  return await axios.get(`${process.env.REACT_APP_CODIKAAPI}/lprovincias`);
}

export async function GetMunicipies(provinceId) {
  return await axios.get(
    `${process.env.REACT_APP_CODIKAAPI}/lmunicipio/${provinceId}`
  );
}

export async function GetSectors(municipeId) {
  return await axios.get(
    `${process.env.REACT_APP_CODIKAAPI}/lsectores/${municipeId}`
  );
}
