
import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
// import InputMask from "react-input-mask";
import { connect } from 'react-redux'

function mapStateToProps(state) {
    return state;
}

class RegisterCategory extends React.Component {
    constructor(props) {
        super(props);
        let editData = this.props.editData;

        this.state = {
            isEdit: editData.nombre === undefined ? false : true,
            nombre: editData.nombre === undefined ? "" : editData.nombre,
            descripcion: editData.descripcion === undefined ? "" : editData.descripcion,
            estado: editData.estado === undefined ? "activo"  : editData.estado,
            isInvalid: false
        };

        if (editData) {
            this.state._id = editData._id;
        }

        this.commit = this.Commit.bind(this);
    }

    Commit = (event) => {
        const form = document.getElementById("frm");
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

            this.setState({
                isInvalid: true
            });
            return;
        }
        this.props.commit({ ...this.state });
    }

    componentDidMount() {
        this.setState({ estado: this.state.estado });

    }

    render() {
        return (
            <Form id="frm" noValidate validated={this.state.isInvalid}>
                <Row>
                    <Col md={6}>
                        <Form.Group>
                            <label>Nombre</label>
                            <Form.Control required defaultValue={this.state.nombre} onChange={e => this.setState({ nombre: e.target.value })} className="input-black" />
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group >
                            <label>Estado</label>
                            <Form.Control  required value={this.state.estado} onChange={e => this.setState({ estado: e.target.value })} className="input-black" as="select">
                                <option value="" hidden="true">Seleccionar estado</option>
                                <option value="" disabled="disabled" default="true">Seleccionar estado</option>
                                <option  key={'activo'} value={'activo'}>Activo</option>
                                <option  key={'inactivo'} value={'inactivo'}>Inactivo</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <label>Descripción</label>
                            <textarea defaultValue={this.state.descripcion} required maxLength="140" onChange={e => this.setState({ descripcion: e.target.value })} className="input-black form-control mb-2" rows="3" />
                            <small className="text-right p-0 m-0">{this.state.descripcion.length} /140</small>
                            <Form.Control.Feedback type="invalid">
                                Campo requerido
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <div className="text-center py-2">
                    <Button className="mx-1 btn-cancel" onClick={this.props.close}>Cancelar</Button>
                    <Button className="mx-1 btn-lightblue" onClick={this.commit}>Guardar</Button>
                </div>
            </Form>
        )
    }
}

export default connect(mapStateToProps)(RegisterCategory)