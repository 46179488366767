import React from "react";
import { Table, Form, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import table from "../../constants/tables.enum";
import { DiffDate, FormatDate, FormatTime } from "../../utils/date.util";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { downloadXLSFile } from "../../utils/download";
import { DownloadRedentionDashboard } from "../../services/redentions.services";
import moment from "moment/moment";

export default class CustomTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      records: [],
      columns: [],
      pageCount: 0,
      pageNumber: 0,
      photoIndex: 0,
      isOpen: false,
      index: 0,
      export: false,
    };
  }

  tableContent(val, indx) {
    let content;

    switch (this.props.tableType) {
      case table.OFFERTS:
        content = (
          <tr key={indx}>
            <td className="text-break w-40">{val.nombre}</td>
            <td>{val.descripcion}</td>
            <td className="text-lightblue">
              <button
                disabled={!val.foto}
                onClick={() => this.setState({ isOpen: true, index: indx })}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                Ver imagen
              </button>
              {this.state.isOpen && this.state.index === indx && (
                <Lightbox
                  mainSrc={val.foto}
                  onCloseRequest={() => this.setState({ isOpen: false })}
                />
              )}
            </td>
            <td>{`${FormatDate(val.fecha_inicial)} - ${FormatDate(
              val.fecha_final
            )}`}</td>
            <td>{DiffDate(val.fecha_inicial, val.fecha_final)} dias</td>
            <td className=" text-center">
              <button
                onClick={() => {
                  this.props.edit(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                onClick={() => {
                  this.props.delete(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        );
        break;

      case table.OFFICES:
        content = (
          <tr key={indx}>
            <td>{val.nombre}</td>
            <td>{val.direccion}</td>
            <td>{val.telefono}</td>
            <td>{val._provincia_?.provincia}</td>
            <td className=" text-center">
              <button
                onClick={() => {
                  this.props.edit(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                onClick={() => {
                  this.props.delete(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        );
        break;

      case table.RESPONSABLE:
        content = (
          <tr key={indx}>
            <td>{val.nombre}</td>
            <td>{val.sucursal}</td>
            <td>{val.telefono}</td>
            <td>{val.cedula}</td>
            <td className=" text-center">
              <button
                onClick={() => {
                  this.props.edit(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                onClick={() => {
                  this.props.delete(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        );
        break;

      case table.CATALOG:
        content = (
          <tr key={indx}>
            <td>{val.nombre}</td>
            <td className="ellipsis">
              <span> {val.descripcion}</span>
            </td>
            <td className="text-lightblue">
              <button
                disabled={!val.foto}
                onClick={() => this.setState({ isOpen: true, index: indx })}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                Ver imagen
              </button>
              {this.state.isOpen && this.state.index === indx && (
                <Lightbox
                  mainSrc={val.foto}
                  onCloseRequest={() => this.setState({ isOpen: false })}
                />
              )}
            </td>
            <td>{`${FormatDate(val.fecha_creacion, "DD/MM/YYYY hh:mm A")}`}</td>
            <td>RD${val.precio_oferta}</td>
            <td>RD${val.precio}</td>
            <td
              className={`offer-status ${
                val.estado === "inactivo" ? " text-danger" : "text-lightblue"
              }`}
            >
              {val._estado_.nombre}
            </td>
            <td className=" text-center">
              <button
                onClick={() => {
                  this.props.view(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-eye text-lightblue pointer"></i>
              </button>
              <button
                onClick={() => {
                  this.props.edit(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                onClick={() => {
                  this.props.delete(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        );
        break;

      case table.CATEGORY:
        content = (
          <tr key={indx}>
            <td>{val.nombre}</td>
            <td>{val.descripcion}</td>
            <td
              className={`offer-status ${
                val.estado === "inactivo" ? " text-danger" : "text-lightblue"
              }`}
            >
              {val._estado_.nombre}
            </td>
            <td className=" text-center">
              <button
                onClick={() => {
                  this.props.edit(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                onClick={() => {
                  this.props.delete(val);
                }}
                className="pointer btn btn-link btn-sm text-lightblue"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        );
        break;
      case table.REDEMPTIONS:
        content = (
          <tr key={indx}>
            <td>{val._socio_?.id_afp}</td>
            <td>{FormatDate(val.fecha_creacion)}</td>
            <td>{FormatTime(val.fecha_creacion)}</td>
            <td>
              <Form.Check
                disabled="true"
                defaultChecked={val.canjeado}
                className="mx-3"
                inline
                type={"checkbox"}
                name={`inline-radio-1`}
              />
              <i
                onClick={() => {
                  this.props.openDialog(val);
                }}
                className="fas fa-eye text-lightblue pointer"
              ></i>
            </td>
          </tr>
        );
        break;

      case table.REDEMPTIONSDASHBOARD:
        content = (
          <tr key={indx}>
            <td>{val?._oferta_?.nombre}</td>
            <td>{val?._sucursal_?.nombre}</td>
            {/* <td>{val?._socio_?.nombres}</td> */}
            <td>{val?.canjeado === true ? "Canjeado" : "Pendiente"}</td>
            <td>{FormatDate(val.fecha_creacion)}</td>
            <td>{FormatTime(val.fecha_creacion)}</td>
            {/* <td>
                        <Form.Check disabled="true" defaultChecked={val.canjeado} className="mx-3" inline type={"checkbox"} name={`inline-radio-1`} />
                        <i onClick={() => { this.props.openDialog(val); }} className="fas fa-eye text-lightblue pointer"></i>
                    </td> */}
          </tr>
        );
        break;

      default:
        break;
    }
    return content;
  }

  componentDidUpdate(oldValues, newValues) {
    if (this.props.items.length === 0 && this.props.columns.length === 0)
      return;

    if (this.state.data !== this.props.items) {
      this.setState({
        data: this.props.items,
        records: this.props.items.slice(0, this.props.perPage),
        pageCount: this.props.items.length / this.props.perPage,
        columns: this.props.columns,
        pageNumber: 0,
        export: this.props.export,
      });
    }
  }

  handlePageClick = (pageData) => {
    let data = this.state.data;
    let selected = pageData.selected;
    let offset = Math.ceil(selected * this.props.perPage);

    this.setState({
      records: data.slice(offset, offset + this.props.perPage),
      pageNumber: selected,
    });
  };

  handleDownload = () => {
    switch (this.props.tableType) {
      case table.REDEMPTIONSDASHBOARD:
        DownloadRedentionDashboard({
          startDate: moment(this.props.filters.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.props.filters.endDate).format("YYYY-MM-DD"),
          search: this.props.filters.search,
        }).then((resp) => {
          downloadXLSFile(resp.data);
        });
        break;
      default:
        break;
    }
  };

  getLastCharapter(val) {
    if (!val) return;
    return val.substr(val.length - 6, val.length).toUpperCase();
  }

  render() {
    return (
      <div>
        <Table className="table-custom rounded-rounded-lg" striped>
          <thead>
            <tr>
              {this.state.columns?.map((val, indx) => (
                <th key={indx}>{val}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.state.records?.map((val, indx) =>
              this.tableContent(val, indx)
            )}
          </tbody>
        </Table>

        <div className="d-flex justify-content-center">
          <div
            style={{
              position: this.state.export ? "relative" : "static",
              left: "50%",
              transform: this.state.export ? "translateX(-50%)" : "",
              width: this.state.export ? "400px" : "",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Siguiente"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              forcePage={this.state.pageNumber}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
          {this.state.export && (
            <div
              style={{
                marginLeft: "auto",
              }}
            >
              <Button onClick={this.handleDownload} className="btn-lightblue">
                Exportar
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
