import { axiosServices } from "./config.services";

export async function GetToken() {
  let param = { apikey: process.env.REACT_APP_APPKEY };
  await axiosServices.post("/api/login", param).then((resp) => {
    sessionStorage.setItem("token", resp.data.result.token);
  });
}

export async function GetTokenAsync() {
  let param = { apikey: process.env.REACT_APP_APPKEY };
  return await axiosServices.post("/api/login", param);
}

export async function Signin(username, password) {
  let param = { usuario: username, contrasena: password };
  return await axiosServices.post("/empleados/login", param);
}

export async function SigninAgency(username, password) {
  let param = { usuario: username, contrasena: password };
  return await axiosServices.post("/usuarios/login", param);
}

export async function AsignProvider(providerId, companyId) {
  let param = { proveedor: providerId, empresa: companyId };
  await axiosServices
    .post("/usuarios/iniciasesionproveedor", param)
    .then((resp) => {
      sessionStorage.setItem("usertoken", resp.data.result.token);
    });
}
