import axios from "axios";
// import encryption, { decryptQR } from "../utils/encryption.util";
import encryption from "../utils/encryption.util";

const urlDomain = window.location.hostname.includes("localhost")
  ? process.env.REACT_APP_URLAPI_LOCALHOST
  : process.env.REACT_APP_URLAPI;

export const axiosServices = axios.create({
  baseURL: urlDomain,
  headers: {
    "Content-Type": "application/json",
  },
});

//request and result interceptor
axiosServices.interceptors.request.use((req) => {
  // const token = sessionStorage.getItem("token");
  // if (token && req.url !== "/api/login") req.headers.Authorization = token;

  const username = sessionStorage.getItem("username");
  const usrtoken = sessionStorage.getItem("usertoken");

  req.headers["usuario"] = username ?? req.data.usuario;
  req.headers["token"] = usrtoken ?? "";

  //add role
  const rolToken = sessionStorage.getItem("rolToken");
  req.headers["rolToken"] = rolToken ?? "";

  //add crypt to referidores endpoints
  if (req.url === "/referidores/create") {
    req.data = { body: encryption.encryptQR(req.data) };
  } else {
    req.data = { body: encryption.encrypt(req.data) };
  }

  return req;
});

axiosServices.interceptors.response.use((res) => {
  var result = "";

  // console.log('res.data.body)', res.data.body)
  if (res.config.url === "/referidores/create") {
    result = encryption.decryptQR(res.data.body);
  } else if (res.config.url.includes("/redenciones/listdashboarddownload")) {
    result = res.data;
  } else {
    result = encryption.decrypt(res.data.body);
  }

  res.data = result;
  return res;
});
